import { createSlice } from '@reduxjs/toolkit'
import { PROGRESS_TYPES } from 'utils/constants'
import { IMissionControlState } from 'utils/interfaces'

const initialState: IMissionControlState = {
  loadingProgress: PROGRESS_TYPES.IDLE,
}

export const missionControlSlice = createSlice({
  name: 'missionControl',
  initialState,
  reducers: {
    setLoadingProgress: (state: IMissionControlState, action: { payload: PROGRESS_TYPES }) => {
      state.loadingProgress = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const MissionControlActions = missionControlSlice.actions

export default missionControlSlice.reducer
