import { yupResolver } from '@hookform/resolvers/yup'
import { FormHelperText, TextField } from '@mui/material'
import { Button } from 'components/Button/Button'
import { useUser } from 'hooks/user'
import { useCallback, useMemo } from 'react'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { emailSchema } from 'utils/validationSchema'
import * as yup from 'yup'

import style from '../Signin.module.css'

interface IFormInputs {
  email: string
}

const RestorePassword: React.FC = () => {
  const { t } = useTranslation()

  const user = useUser()

  const restoreProgress = useMemo(() => user?.state.restorePasswordProgress, [user?.state.restorePasswordProgress])
  const methods = useForm<IFormInputs>({
    resolver: yupResolver(yup.object().shape({ email: emailSchema() })),
  })

  const onSubmit: SubmitHandler<IFormInputs> = useCallback(
    data => {
      user?.actions.restorePassword(data.email)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className={style.wrapper}>
      {restoreProgress !== PROGRESS_TYPES.SUCCESS && (
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <div className={style.fld}>
            <TextField
              {...methods.register('email')}
              id="email"
              name="email"
              variant="outlined"
              autoComplete="username"
              placeholder={t('signin.emailPlaceholder')}
              inputProps={{
                'data-test-id': 'email',
              }}
              aria-describedby="email-error-text"
              error={!!methods.formState.errors.email || restoreProgress === PROGRESS_TYPES.ERROR}
              fullWidth
            />
            <FormHelperText id="email-error-text">{t(methods.formState.errors.email?.message || '')}</FormHelperText>
          </div>
          <Button.Standart
            dataTestId={'submit-btn'}
            disabled={restoreProgress === PROGRESS_TYPES.WORK}
            loading={restoreProgress === PROGRESS_TYPES.WORK}
            className={style.signinButton}
          >
            {t('restore_password.reset_password')}
          </Button.Standart>
        </form>
      )}
      {restoreProgress === PROGRESS_TYPES.SUCCESS && (
        <div className={style.restoreText}>{t('restore_password.email_sent')}</div>
      )}
      <div data-test-id={'restore'} className={style.forgotPasswordLink}>
        {t('restore_password.already_have')}
        <Link className={style.href} to={PATH.SIGNIN} data-test-id={getTestId('link-forgot-password')}>
          {t('restore_password.signin')}
        </Link>
      </div>
    </div>
  )
}

export default RestorePassword
