import { RootState } from 'utils/interfaces'

export const selectMissionLoadingProgress = (state: RootState) => state.mission.loadingProgress
export const selectSavingZoneProgress = (state: RootState) => state.mission.savingZoneProgress
export const selectMissionCalculatingStatus = (state: RootState) => state.mission.status
export const selectMissionReadyForCalculation = (state: RootState) => state.mission.isReadyForCalculation
export const selectZones = (state: RootState) => [...state.mission.cleaningZones, ...state.mission.restrictZones]
export const selectCleaningZones = (state: RootState) => state.mission.cleaningZones
export const selectRestrictZones = (state: RootState) => state.mission.restrictZones
export const selectTransferRoutes = (state: RootState) => state.mission.transferRoutes
export const selectMode = (state: RootState) => state.mission.mode
export const selectMapMode = (state: RootState) => state.mission.mapMode
export const selectMission = (state: RootState) => state.mission
export const selectMissionEditingZone = (state: RootState) => state.mission.editingZone
export const selectMissionTempZone = (state: RootState) => state.mission.tempZone
export const selectMissionEditingLaunchPoint = (state: RootState) => state.mission.editingLaunchPoint
export const selectMissionTempLaunchPoint = (state: RootState) => state.mission.tempLaunchPoint
export const selectMissionEditingTransfer = (state: RootState) => state.mission.editingTransfer
export const selectMissionInfo = (state: RootState) => state.mission.missionInfo
export const selectCleaningRoutes = (state: RootState) => state.mission.cleaningRoutes
export const selectHighlightedFeatureIdInList = (state: RootState) => state.mission.highlightedFeatureIdInList
export const selectHighlightedFeature = (state: RootState) => state.mission.highlightedFeature
export const selectShowCleaningZones = (state: RootState) => state.mission.showCleaningZones
export const selectShowRestrictZones = (state: RootState) => state.mission.showRestrictZones
export const selectGeoTiffLayer = (state: RootState) => state.mission.geoTiffLayer
export const selectMissionStartPoint = (state: RootState) => state.mission.missionPoints.startPoint
export const selectMissionFinishPoint = (state: RootState) => state.mission.missionPoints.finishPoint
export const selectMissionTempPoint = (state: RootState) => state.mission.missionPoints.tempPoint
export const selectMissionEditingPoint = (state: RootState) => state.mission.missionPoints.editingPoint
export const selectMissionPointRoutes = (state: RootState) => state.mission.missionPoints.routes
export const selectMissionVisualSettings = (state: RootState) => state.mission.visualSettings
export const selectMissionTempVisualSettings = (state: RootState) => state.mission.tempVisualSettings
