import Loader from 'components/Loader/Loader'
import { UserProvider, getInitialAccountState } from 'hooks/user'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import App from './App'
import './i18n'
import reportWebVitals from './reportWebVitals'
import store from './store/store'

import './index.css'

const init = async () => {
  const initialAccountState = await getInitialAccountState()
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

  root.render(
    <React.StrictMode>
      <Suspense fallback={<Loader />}>
        <UserProvider initialState={initialAccountState}>
          <Provider store={store}>
            <App />
          </Provider>
        </UserProvider>
      </Suspense>
    </React.StrictMode>
  )
}

init().then(() => console.info('App is init. 🔥'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
