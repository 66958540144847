import { AccService, WhoAmI } from 'api/schema/index'

export const AccountApi = {
  login: async (email: string, password: string): Promise<WhoAmI> => {
    const user = await AccService.accLoginCreate({ email, password })
    return user
  },
  fetchUser: async (): Promise<WhoAmI> => {
    const user = await AccService.accWhoamiRetrieve()
    return user
  },
  logout: async () => {
    await AccService.accLogoutCreate()
  },
  restorePassword: async (email: string): Promise<void> => {
    await AccService.accPasswordResetCreate({ email })
  },
  setNewPassword: async (token: string, password: string): Promise<void> => {
    await AccService.accPasswordResetConfirmCreate({ token, password })
  },
}
