import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MissionActions } from 'store/actions/mission'
import { selectMissionEditingPoint, selectMissionTempPoint } from 'store/selectors/mission'
import store from 'store/store'
import { missionThunks } from 'store/thunks/mission'
import { TRANSFER_TYPE } from 'utils/constants'
import { IPoint } from 'utils/interfaces'

import CommonRouteItem from './CommonRouteItem/CommonRouteItem'

const StartFinishPointItem = ({
  type,
  missionPoint,
  hideActions,
}: {
  type: TRANSFER_TYPE
  missionPoint: IPoint
  hideActions?: boolean
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const missionEditingPoint = useSelector(selectMissionEditingPoint)
  const missionTempPoint = useSelector(selectMissionTempPoint)

  const onPointClicked = () => {
    dispatch(MissionActions.setHighlightedFeature(missionPoint))
  }

  const onEditPointClicked = () => {
    store.dispatch(missionThunks.setEditingPoint(missionPoint))
  }

  const onRemovePointClicked = () => {
    if (type === TRANSFER_TYPE.START) {
      store.dispatch(missionThunks.deleteStartPoint())
    } else {
      store.dispatch(missionThunks.deleteFinishPoint())
    }
  }

  const onCancelEditingClicked = (e: any) => {
    e.stopPropagation()
    store.dispatch(missionThunks.cancelEditingPoint())
  }

  const onSaveClicked = (e: any) => {
    e.stopPropagation()
    store.dispatch(missionThunks.editPoint(type, missionTempPoint))
  }

  const text = useMemo(() => {
    switch (type) {
      case TRANSFER_TYPE.START:
        return t('view_zones.start_point')
      case TRANSFER_TYPE.FINISH:
        return t('view_zones.finish_point')
    }
  }, [t, type])

  const editing = useMemo(() => {
    return missionEditingPoint === missionPoint
  }, [missionEditingPoint, missionPoint])

  return missionPoint?.id ? (
    <CommonRouteItem
      id={missionPoint.id}
      text={text}
      itemType={type}
      editing={editing}
      onClick={onPointClicked}
      onEdit={onEditPointClicked}
      onCancel={onCancelEditingClicked}
      onRemove={onRemovePointClicked}
      onSave={onSaveClicked}
      hideActions={hideActions}
    />
  ) : null
}

export default StartFinishPointItem
