import MapboxCommon from 'components/MapboxMap/MapboxCommon'
import { IOverlayItem } from 'components/MapboxMap/OverlayControl/OverlayControl'
import { Feature } from 'geojson'
import React, { useImperativeHandle } from 'react'
import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MissionActions } from 'store/actions/mission'
import {
  selectGeoTiffLayer,
  selectMission,
  selectShowCleaningZones,
  selectShowRestrictZones,
} from 'store/selectors/mission'
import store from 'store/store'
import { missionThunks } from 'store/thunks/mission'
import { MISSION_MAP_DRAWING_MODES } from 'utils/constants'
import { ITrajectory } from 'utils/interfaces'
import { IMapboxElement } from 'utils/interfaces'
import { zoomMapTo } from 'utils/mapUtils'

import DrawControl from './DrawControl/DrawControl'
import drawStyles from './DrawControl/drawStyles'
import CleaningPointsOnMap from './Layers/CleaningPointsOnMap'
import CleaningRoutesOnMap from './Layers/CleaningRoutesOnMap'
import CleaningZonesNamesOnMap from './Layers/CleaningZonesNamesOnMap'
import CleaningZonesOnMap from './Layers/CleaningZonesOnMap'
import GeoTiffLayer from './Layers/GeoTiffLayer'
import MissionStartFinishPoints from './Layers/MissionStartFinishPoints'
import RestrictZonesOnMap from './Layers/RestrictZonesOnMap'
import TransferRoutesOnMap from './Layers/TransferRoutesOnMap'

interface MissionMapProps {
  children?: React.ReactNode
}

const MissionMap = React.forwardRef<IMapboxElement, MissionMapProps>((props, ref) => {
  const dispatch = useDispatch()
  const mapRef = useRef<IMapboxElement>(null)
  const mission = useSelector(selectMission)
  const showCleaningZones = useSelector(selectShowCleaningZones)
  const showRestrictZones = useSelector(selectShowRestrictZones)
  const geoTiffLayer = useSelector(selectGeoTiffLayer)

  const onFeatureCreated = (evt: { features: Feature[] }) => {
    store.dispatch(missionThunks.createFeature(evt))
  }

  const onFeatureGeometryEdited = (evt: { features: Feature[]; action: string }) => {
    store.dispatch(missionThunks.editFeature(evt))
  }

  useImperativeHandle(ref, () => ({
    getMap() {
      return mapRef?.current?.getMap()
    },
  }))

  useEffect(() => {
    if (mission.highlightedFeature) {
      zoomMapTo(mapRef.current?.getMap(), [mission.highlightedFeature])
    }
  }, [mission.highlightedFeature])

  const initMap = () => {
    const map = mapRef.current?.getMap()
    zoomMapTo(map, [...mission.cleaningZones, ...mission.restrictZones, ...mission.cleaningRoutes])
    const setHighlightedFeature = (id: string | undefined) => {
      dispatch(MissionActions.setHighlightedFeatureInList(id))
    }
    map?.on('click', () => setHighlightedFeature(undefined))
    map?.on('click', 'gl-draw-polygon-fill-cleaning-zone', e =>
      setHighlightedFeature(e?.features && e?.features[0].properties?.id_on_map)
    )
    map?.on('click', 'restrict-zones-fill', e =>
      setHighlightedFeature(e?.features && e?.features[0].properties?.id_on_map)
    )
    map?.on('click', 'transfer-route-halo-style', e =>
      setHighlightedFeature(e?.features && e?.features[0].properties?.id_on_map)
    )
    map?.on('click', 'gl-draw-cleaning-route-launch-point', e =>
      setHighlightedFeature(e?.features && e?.features[0].properties?.id_on_map)
    )
    map?.on('click', 'gl-draw-cleaning-routes', e =>
      setHighlightedFeature(e?.features && e?.features[0].properties?.id_on_map)
    )
    map?.on('click', 'mission-finish-point1', e =>
      setHighlightedFeature(e?.features && e?.features[0].properties?.id_on_map)
    )
    map?.on('click', 'mission-start-point', e =>
      setHighlightedFeature(e?.features && e?.features[0].properties?.id_on_map)
    )
  }

  const onMapMoved = () => {
    dispatch(MissionActions.setHighlightedFeature(undefined))
  }

  const launchPoints = useMemo(() => {
    return mission.launchPoints.filter(launchPoint => launchPoint !== mission.editingLaunchPoint)
  }, [mission.editingLaunchPoint, mission.launchPoints])

  const restrictZones = useMemo(() => {
    return mission.restrictZones.filter(restrictZone => restrictZone.id !== mission.editingZone?.id)
  }, [mission.editingZone, mission.restrictZones])

  const cleaningZones = useMemo(() => {
    return mission.cleaningZones.filter(cleaningZone => cleaningZone.id !== mission.editingZone?.id)
  }, [mission.editingZone, mission.cleaningZones])

  const transferRoutes = useMemo(() => {
    let routes: Array<ITrajectory> = []
    routes.push(...mission.missionPoints.routes, ...mission.transferRoutes)
    routes = routes.filter(route => route.id !== mission.editingTransfer?.id)
    return routes
  }, [mission.transferRoutes, mission.missionPoints.routes, mission.editingTransfer?.id])

  const startPoint = useMemo(() => {
    if (mission.missionPoints.startPoint !== mission.missionPoints.editingPoint) {
      return mission.missionPoints.startPoint
    }
  }, [mission.missionPoints.editingPoint, mission.missionPoints.startPoint])

  const finishPoint = useMemo(() => {
    if (mission.missionPoints.finishPoint !== mission.missionPoints.editingPoint) {
      return mission.missionPoints.finishPoint
    }
  }, [mission.missionPoints.editingPoint, mission.missionPoints.finishPoint])

  const switchShowGeoTiffLayer = () => {
    if (geoTiffLayer.layer) {
      dispatch(MissionActions.setShowGeoTiffLayer(!geoTiffLayer.show))
    }
  }

  const overlayItems: IOverlayItem[] = [
    {
      onClick: switchShowGeoTiffLayer,
      active: geoTiffLayer.show,
      disabled: !geoTiffLayer.layer?.urlTiles,
      content: <>Geotiff layer</>,
    },
    {
      onClick: () => dispatch(MissionActions.setShowCleaningZones(!showCleaningZones)),
      active: showCleaningZones,
      disabled: false,
      content: <>Cleaning Zones</>,
    },
    {
      onClick: () => dispatch(MissionActions.setShowRestrictZones(!showRestrictZones)),
      active: showRestrictZones,
      disabled: false,
      content: <>Restrict Zones</>,
    },
  ]

  return (
    <MapboxCommon ref={mapRef} onMapMoved={onMapMoved} onMapInited={initMap} overlayItems={overlayItems}>
      <GeoTiffLayer show={geoTiffLayer.show} />
      <RestrictZonesOnMap restrictZones={showRestrictZones ? restrictZones : []} />
      <CleaningZonesOnMap cleaningZones={showCleaningZones ? cleaningZones : []} />
      <CleaningRoutesOnMap cleaningRoutes={showCleaningZones ? mission.cleaningRoutes : []} />
      <DrawControl
        userProperties={true}
        displayControlsDefault={false}
        onCreate={onFeatureCreated}
        onUpdate={onFeatureGeometryEdited}
        onDelete={() => {}}
        mode={mission.mapMode}
        tempZone={mission.tempZone}
        tempLaunchPoint={mission.tempLaunchPoint}
        tempMissionPoint={mission.missionPoints.tempPoint}
        styles={drawStyles}
        tempTransfer={mission.tempTransfer}
        setEditingTempLaunchPoint={() =>
          dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE_LAUNCH_POINT))
        }
        setEditingTempZone={() => dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE))}
      />
      <TransferRoutesOnMap transferRoutes={showCleaningZones ? transferRoutes : []} />
      <CleaningPointsOnMap
        cleaningRoutes={showCleaningZones ? mission.cleaningRoutes : []}
        launchPoints={showCleaningZones ? launchPoints : []}
      />
      <MissionStartFinishPoints startPoint={startPoint} finishPoint={finishPoint} />
      <CleaningZonesNamesOnMap cleaningZones={showCleaningZones ? cleaningZones : []} />
      {props.children}
    </MapboxCommon>
  )
})

export default MissionMap
