import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { isFeature } from '@mapbox/mapbox-gl-draw/src/lib/common_selectors'
import cloneDeep from 'lodash.clonedeep'

const DirectSelectMissionPoint = cloneDeep(MapboxDraw.modes.simple_select)

DirectSelectMissionPoint.clickInactive = function () {}

DirectSelectMissionPoint.clickNoTarget = function () {}

DirectSelectMissionPoint.dragFeature = function () {}

DirectSelectMissionPoint.onMouseDown = function (state, e) {
  if (isFeature(e) && state.initiallySelectedFeatureIds.includes(e.featureTarget?.properties?.id)) {
    this.clickOnFeature(state, e)
    return this.startOnActiveFeature(state, e)
  }
}

DirectSelectMissionPoint.onTap = DirectSelectMissionPoint.onClick = function (state, e) {
  if (!state.initiallySelectedFeatureIds.includes(e.featureTarget?.properties?.id)) {
    this.map.fire('click', e)
  }
}

export default DirectSelectMissionPoint
