import { Switch, SwitchProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const SwitchStyled = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 60,
  height: 30,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 5,
    marginLeft: 7,
    transitionDuration: '300ms',
    '@keyframes animatedBackground': {
      from: {
        rotate: '0deg',
      },
      to: {
        rotate: '359deg',
      },
    },
    '&.Mui-checked': {
      transform: 'translateX(27px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#3AB084',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#3AB084',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#FFF',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5,
      '&:after': {
        content: '""',
        position: 'absolute',
        transform: 'translateY(-50%), translateX(-50%)',
        width: 16,
        height: 16,
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 41 41"><path d="M18.4583 6.37171V0.083374C8.24992 1.10421 0.083252 9.90379 0.083252 20.5C0.083252 31.0963 8.24992 39.8959 18.4583 40.9167V34.6284C12.3333 33.6484 6.20825 27.6867 6.20825 20.5C6.20825 13.3134 12.3333 7.35171 18.4583 6.37171ZM34.7303 18.4584H40.9166C39.957 8.25004 32.7499 1.04296 22.5416 0.083374V6.37171C28.6666 7.24962 33.8524 12.3334 34.7303 18.4584ZM22.5416 34.6284V40.9167C32.7499 39.9571 39.957 32.75 40.9166 22.5417H34.7303C33.8524 28.6667 28.6666 33.7505 22.5416 34.6284Z" fill="white"/></svg>')`,
        animation: 'animatedBackground 2s linear infinite',
        left: '23px',
        top: '6px',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 19,
    height: 19,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16,
    backgroundColor: '#63729A',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

export default SwitchStyled
