import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { cursors, events, modes } from '@mapbox/mapbox-gl-draw/src/constants'

const LaunchPointMode = MapboxDraw.modes.draw_point

LaunchPointMode.onClick = function (state, e) {
  this.updateUIClasses({ mouse: cursors.MOVE })
  state.point.updateCoordinate('', e.lngLat.lng, e.lngLat.lat)
  this.map.fire(events.CREATE, {
    features: [state.point.toGeoJSON()],
  })
  this.changeMode(modes.SIMPLE_SELECT, { featureIds: [state.point.id] })
}

export default LaunchPointMode
