import { Box } from '@mui/material'

import style from './TabPanel.module.css'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  disablePadding?: boolean
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, disablePadding, ...other } = props
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={value === index ? style.tabContent : style.invisible}
    >
      {value === index && (
        <Box
          sx={{
            p: props.disablePadding ? 0 : 4,
            flexGrow: 1,
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  )
}

export default TabPanel
