import { ReactComponent as LoaderSvg } from 'images/loader.svg'

import style from './Loader.module.css'

const Loader = () => (
  <div className={style.loader}>
    <div className={style.inner}>
      <LoaderSvg />
    </div>
  </div>
)

export default Loader
