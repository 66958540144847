import Menu, { MenuProps } from '@mui/material/Menu'
import { styled } from '@mui/material/styles'

export const StyledDropdown = styled((props: MenuProps) => <Menu {...props} />)(() => ({
  '& .MuiPaper-root': {
    background: '#ffffff',
    boxShadow: '0px 6px 10px rgba(37, 44, 61, 0.25)',
    borderRadius: '0px',
    marginTop: '2px',
  },
  '& .MuiList-root': {
    padding: '5px 0',
  },
}))
