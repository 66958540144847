import clsx from 'clsx'
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, LineString, Point } from 'geojson'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CircleLayer, Layer, LineLayer, Source, SymbolLayer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { selectMapMode } from 'store/selectors/mission'
import turf from 'turf'
import { MISSION_MAP_DRAWING_MODES } from 'utils/constants'

import style from './RulerControl.module.css'

const measureLinesStyle: LineLayer = {
  id: 'measure-lines',
  type: 'line',
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-color': '#000',
    'line-width': 1.5,
  },
  filter: ['in', '$type', 'LineString'],
}

const measurePointsStyle: CircleLayer = {
  id: 'measure-points',
  type: 'circle',
  paint: {
    'circle-radius': 3,
    'circle-color': '#000',
  },
  filter: ['in', '$type', 'Point'],
}

const measuredDistanceStyle: SymbolLayer = {
  id: 'distance-layer',
  type: 'symbol',
  layout: {
    'text-field': ['get', 'distance'],
    'text-font': ['Open Sans Regular'],
    'text-offset': [1, 1],
    'text-anchor': 'center',
  },
  paint: {
    'text-halo-color': 'white',
    'text-halo-width': 2,
    'text-halo-blur': 1,
  },
}

const RulerControl = ({ map }: { map?: mapboxgl.Map }) => {
  const mapMode = useSelector(selectMapMode)
  const [rulerMode, setRulerMode] = useState(false)

  const [geojsonFeatures, setGeojsonFeatures] = useState<Feature[]>([])
  const geojsonData: FeatureCollection<Geometry> = useMemo(() => {
    const linestring: Feature<LineString, GeoJsonProperties> = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
      properties: {},
    }
    linestring.geometry.coordinates = geojsonFeatures.map(point => (point.geometry as Point).coordinates).reverse()

    if (geojsonFeatures.length > 1) {
      const distance = turf.lineDistance(linestring, 'meters')
      linestring.properties = { distance: `${Math.round(distance * 10) / 10}m` }
    }
    return {
      type: 'FeatureCollection',
      features: [...geojsonFeatures, linestring],
    }
  }, [geojsonFeatures])

  const geojsonDistancesData: FeatureCollection<Point> = useMemo(() => {
    const defaultObject: FeatureCollection<Point> = {
      type: 'FeatureCollection',
      features: [],
    }
    if (geojsonFeatures.length < 2) {
      return defaultObject
    }
    for (let i = 0; i < geojsonFeatures.length - 1; i++) {
      const point1 = geojsonFeatures[i] as Feature<Point>
      const point2 = geojsonFeatures[i + 1] as Feature<Point>
      const distance = turf.distance(point1, point2, 'meters')
      defaultObject.features.push({
        type: 'Feature',
        geometry: turf.midpoint(point1, point2).geometry,
        properties: {
          distance: `${Math.round(distance * 10) / 10}m`,
        },
      })
    }
    return defaultObject
  }, [geojsonFeatures])

  const disabled = mapMode !== MISSION_MAP_DRAWING_MODES.VIEW_ZONES
  useEffect(() => {
    if (mapMode !== MISSION_MAP_DRAWING_MODES.VIEW_ZONES) {
      setGeojsonFeatures([])
      setRulerMode(false)
    }
  }, [mapMode])

  const onButtonClick = useCallback(() => {
    setRulerMode(!rulerMode)
    if (rulerMode) {
      setGeojsonFeatures([])
    }
  }, [rulerMode])

  const onMouseClick = useCallback(
    (e: any) => {
      if (!rulerMode) {
        return
      }

      const features = map?.queryRenderedFeatures(e.point, {
        layers: ['measure-points'],
      })

      // If a feature was clicked, remove it from the map.
      if (features && features.length) {
        const feature = features[0] as Feature<Geometry, GeoJsonProperties>
        const id = feature.properties?.id
        const newGeojsonFeatures = geojsonFeatures.filter(point => point.properties?.id !== id)
        setGeojsonFeatures(newGeojsonFeatures)
      } else {
        const point: Feature<Geometry, GeoJsonProperties> = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
          properties: {
            id: String(new Date().getTime()),
          },
        }
        setGeojsonFeatures([...geojsonFeatures, point])
      }
    },
    [geojsonFeatures, map, rulerMode]
  )

  const onMouseMove = useCallback(
    (e: any) => {
      if (!map) {
        return
      }
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['measure-points'],
      })
      if (rulerMode) {
        map.getCanvas().style.cursor = features.length ? 'pointer' : 'crosshair'
      } else {
        map.getCanvas().style.cursor = ''
      }
    },
    [map, rulerMode]
  )

  useEffect(() => {
    map?.on('click', onMouseClick)
    map?.on('mousemove', onMouseMove)
    return () => {
      map?.off('click', onMouseClick)
      map?.off('mousemove', onMouseMove)
    }
  }, [onMouseMove, onMouseClick, map])

  return (
    <>
      <Source id="ruler" type="geojson" data={geojsonData}>
        <Layer {...measureLinesStyle} />
        <Layer {...measurePointsStyle} />
        <Layer {...measuredDistanceStyle} />
      </Source>
      <Source id="distances" type="geojson" data={geojsonDistancesData}>
        <Layer {...measuredDistanceStyle} id="distances" />
      </Source>
      <div className={style.root}>
        <button
          className={rulerMode ? clsx(style.button, style.buttonActive) : style.button}
          disabled={disabled}
          onClick={onButtonClick}
        >
          <span className={clsx('mapboxgl-ctrl-icon', style.icon)} title="Ruler" />
        </button>
      </div>
    </>
  )
}

export default RulerControl
