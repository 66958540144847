import { Button } from 'components/Button/Button'
import GarageOnMap from 'components/GarageOnMap/GarageOnMap'
import MapboxCommon from 'components/MapboxMap/MapboxCommon'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import { Point } from 'geojson'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGarage, IMapboxElement } from 'utils/interfaces'

import style from '../AddRobotsModal.module.css'

const GarageView = ({
  garage,
  isNew,
  onNameChanged,
  onPointChanged,
  onGarageDeleted,
}: {
  garage: IGarage
  isNew: boolean
  onNameChanged: (name: string) => void
  onPointChanged: (point: Point) => void
  onGarageDeleted: () => void
}) => {
  const { t } = useTranslation()
  const mapRef = useRef<IMapboxElement>(null)
  const [mapInited, setMapInited] = useState(false)

  const onMapInited = useCallback(() => {
    setMapInited(true)
    if (garage.point) {
      mapRef.current
        ?.getMap()
        ?.flyTo({ animate: true, duration: 100, center: [garage.point.coordinates[0], garage.point.coordinates[1]] })
    }
  }, [garage.point])

  useEffect(() => {
    if (garage.point) {
      mapRef.current
        ?.getMap()
        ?.flyTo({ animate: true, duration: 100, center: [garage.point.coordinates[0], garage.point.coordinates[1]] })
    }
  }, [garage.point])

  return (
    <>
      <div className={style.rowAlignEnd}>
        <TextFieldDescribed
          className={style.textfield}
          description={'Maintance name'}
          value={garage.name}
          placeholder={t('robots.robot_name_placeholder')}
          onChange={e => onNameChanged(e.target.value)}
        />
        <Button.Outlined className={style.buttonDelete} onClick={onGarageDeleted} disabled={isNew}>
          Delete Unit
        </Button.Outlined>
      </div>
      <div className={style.map}>
        <MapboxCommon disableRuler={true} ref={mapRef} onMapInited={onMapInited}>
          {mapInited && (
            <GarageOnMap
              map={mapRef.current?.getMap()}
              garages={garage.point ? [garage.point] : []}
              onPointChanged={onPointChanged}
            />
          )}
        </MapboxCommon>
      </div>
    </>
  )
}

export default GarageView
