import clsx from 'clsx'
import { ReactNode } from 'react'

import style from './Box.module.css'

function Box({ children, className }: { children: ReactNode; className?: string }) {
  return <div className={clsx(style.root, className)}>{children}</div>
}

export default Box
