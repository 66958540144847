import { Tab } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledTab = styled(Tab)({
  '&.MuiTab-root.Mui-selected': {
    color: 'rgba(0, 10, 25, 0.8)',
  },
  '&.MuiTab-root.': {
    color: '#6D7484',
  },
})
