import UserMenu from '../UserMenu/UserMenu'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { ReactComponent as ArrowSvg } from 'images/icons/arrow_select.svg'
import { useState } from 'react'
import getTestId from 'utils/getTestId'

import style from './User.module.css'

const User: React.FC<{ email?: string }> = ({ email }) => {
  const [open, setOpen] = useState(false)

  const onClick = (): void => {
    setOpen(prev => !prev)
  }

  const onClickAway = (): void => {
    setOpen(prev => !prev)
  }

  return (
    <div className={style.userMenuBlock} onClick={onClick} data-test-id={getTestId('user-avatar')}>
      <div className={style.avatar}>
        <span className={style.avatarText} data-test-id={getTestId('avatar-text')}>
          {email?.substring(0, 2).toUpperCase()}
        </span>
      </div>
      <div className={style.cutText} data-test-id={getTestId('email-cut')}>
        {email}
      </div>
      <ArrowSvg />
      {open ? (
        <ClickAwayListener onClickAway={onClickAway}>
          <UserMenu />
        </ClickAwayListener>
      ) : null}
    </div>
  )
}

export default User
