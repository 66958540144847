import Loader from 'components/Loader/Loader'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { MissionActions } from 'store/actions/mission'
import { selectMissionLoadingProgress } from 'store/selectors/mission'
import store from 'store/store'
import { missionThunks } from 'store/thunks/mission'
import { PROGRESS_TYPES } from 'utils/constants'

import MissionView from './MissionView/MissionView'

import style from './Mission.module.css'

const Mission = () => {
  const dispatch = useDispatch()
  const loadingProgress = useSelector(selectMissionLoadingProgress)
  const params = useParams()
  const missionId = parseFloat(params.id || '')

  useEffectOnce(() => {
    store.dispatch(missionThunks.fetchMission(missionId))
    return () => dispatch(MissionActions.resetMission())
  })

  const stateList = {
    [`${PROGRESS_TYPES.SUCCESS}`]: <MissionView />,
    [`${PROGRESS_TYPES.WORK}`]: <Loader />,
    [`${PROGRESS_TYPES.ERROR}`]: <span>Error</span>,
    [`${PROGRESS_TYPES.IDLE}`]: <span>Waiting</span>,
    undefined: <span>Something went wrong</span>,
  }

  return <div className={style.container}>{stateList[loadingProgress]}</div>
}

export default Mission
