import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { DrawCustomMode } from '@mapbox/mapbox-gl-draw'
import { GeoJSON } from 'geojson'

export const ViewZonesDrawMode: DrawCustomMode = {
  toDisplayFeatures: function (
    this: MapboxDraw.DrawCustomModeThis & MapboxDraw.DrawCustomMode<any, any>,
    state: any,
    geojson: GeoJSON,
    display: (geojson: GeoJSON) => void
  ): void {
    display(geojson)
  },
  onSetup: opts => {
    var state = {}
    // state.count = opts.count || 0;
    return state
  },
}
