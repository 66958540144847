import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { Feature } from 'geojson'
import { useEffect } from 'react'
import { useControl } from 'react-map-gl'
import type { ControlPosition, MapRef } from 'react-map-gl'
import { MISSION_MAP_DRAWING_MODES } from 'utils/constants'
import { ILaunchPoint, IPoint, ITransferRoute, IZone } from 'utils/interfaces'

import DirectSelectMissionPoint from './modes/DirectSelectMissionPoint'
import DirectSelectTransferRouteMode from './modes/DirectSelectTransferRouteMode'
import DirectSelectZoneMode from './modes/DirectSelectZoneMode'
import DrawPolygonMode from './modes/DrawPolygonMode'
import DrawPointMode from './modes/LaunchPointMode'
import { ViewZonesDrawMode } from './modes/ViewZonesDrawMode'

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition
  mode: MISSION_MAP_DRAWING_MODES
  tempZone: IZone | undefined
  tempLaunchPoint: ILaunchPoint | undefined
  tempTransfer: ITransferRoute | undefined
  tempMissionPoint: IPoint | undefined
  onCreate: (evt: { features: Feature[] }) => void
  onUpdate: (evt: { features: Feature[]; action: string }) => void
  onDelete: (evt: { features: Feature[] }) => void
  setEditingTempLaunchPoint: () => void
  setEditingTempZone: () => void
}

export default function DrawControl(props: DrawControlProps) {
  const control = useControl<MapboxDraw>(
    () =>
      new MapboxDraw({
        ...props,
        defaultMode: 'view_zones',
        modes: Object.assign(
          {
            view_zones: ViewZonesDrawMode,
            draw_polygon: DrawPolygonMode,
            direct_select_zone: DirectSelectZoneMode,
            draw_point: DrawPointMode,
            direct_select_transfer_route: DirectSelectTransferRouteMode,
            direct_select_mission_point: DirectSelectMissionPoint,
          },
          MapboxDraw.modes
        ),
      }),
    ({ map }: { map: MapRef }) => {
      map.on('draw.create', props.onCreate)
      map.on('draw.update', props.onUpdate)
      map.on('draw.delete', props.onDelete)
      map.on('click', 'gl-draw-launch-point.cold', props.setEditingTempLaunchPoint)
      map.on('click', 'gl-draw-launch-point-center.cold', props.setEditingTempLaunchPoint)
      map.on('click', 'gl-draw-polygon-fill.cold', props.setEditingTempZone)
    },
    ({ map }: { map: MapRef }) => {
      map.off('draw.create', props.onCreate)
      map.off('draw.update', props.onUpdate)
      map.off('draw.delete', props.onDelete)
      map.off('click', 'gl-draw-launch-point.cold', props.setEditingTempLaunchPoint)
      map.off('click', 'gl-draw-launch-point-center.cold', props.setEditingTempLaunchPoint)
      map.off('click', 'gl-draw-polygon-fill.cold', props.setEditingTempZone)
    },
    {
      position: props.position,
    }
  ) as MapboxDraw

  useEffect(() => {
    if (props.tempZone) {
      if (!control.get(String(props.tempZone?.id))) {
        control.add(props.tempZone)
      }
    }

    if (props.tempLaunchPoint) {
      if (!control.get(String(props.tempLaunchPoint?.id))) {
        control.add(props.tempLaunchPoint)
      }
    } else {
      control.getAll().features.forEach(feature => {
        const featureInProps =
          props.tempLaunchPoint?.id === feature.id ||
          props.tempZone?.id === feature.id ||
          props.tempTransfer?.id === feature.id
        if (!featureInProps) {
          control.delete(String(feature.id))
        }
      })
    }

    if (props.tempTransfer) {
      if (!control.get(String(props.tempTransfer?.id))) {
        control.add(props.tempTransfer)
      }
    }

    if (props.tempMissionPoint) {
      if (!control.get(String(props.tempMissionPoint?.id))) {
        control.add(props.tempMissionPoint)
      }
    }
  }, [control, props.tempLaunchPoint, props.tempMissionPoint, props.tempTransfer, props.tempZone])

  useEffect(() => {
    switch (props.mode) {
      case MISSION_MAP_DRAWING_MODES.VIEW_ZONES:
        control.deleteAll()
        control.changeMode('view_zones')
        break
      case MISSION_MAP_DRAWING_MODES.DRAW_POLYGON:
        control.changeMode('draw_polygon')
        break
      case MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE:
        if (props.tempZone && props.tempZone.id) {
          control.changeMode('direct_select_zone', { featureId: String(props.tempZone.id) })
        }
        break
      case MISSION_MAP_DRAWING_MODES.ADD_ZONE_LAUNCH_POINT:
        control.changeMode('draw_point')
        break
      case MISSION_MAP_DRAWING_MODES.ADD_MISSION_START_POINT:
      case MISSION_MAP_DRAWING_MODES.ADD_MISSION_FINISH_POINT:
        control.changeMode('draw_point')
        break
      case MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_TRANSFER_ROUTE:
        if (props.tempTransfer && props.tempTransfer.id) {
          control.changeMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_TRANSFER_ROUTE, {
            featureId: String(props.tempTransfer.id),
          })
        }
        break
      case MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_MISSION_POINT:
        if (props.tempMissionPoint && props.tempMissionPoint.id) {
          control.changeMode('direct_select_mission_point', {
            featureIds: [String(props.tempMissionPoint.id)],
          })
        }
        break
      case MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE_LAUNCH_POINT:
        if (props.tempLaunchPoint && props.tempLaunchPoint.id) {
          control.changeMode('direct_select_mission_point', {
            featureIds: [String(props.tempLaunchPoint.id)],
          })
        }
        break
      default:
        control.changeMode('view_zones')
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode])

  return null
}
