import clsx from 'clsx'
import { ReactComponent as LoaderSvg } from 'images/icons/loader.svg'
import getTestId from 'utils/getTestId'

import style from '../Button.module.css'

export type TButton = {
  className?: string
  disabled?: boolean
  loading?: boolean
  size?: 'normal' | 'medium'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  dataTestId?: string
  type?: 'submit' | 'button'
}

function CommonButton({
  disabled = false,
  loading = false,
  className,
  onClick = () => {},
  size = 'medium',
  children,
  dataTestId = 'button',
  type = 'submit',
}: React.PropsWithChildren<TButton>) {
  return (
    <button
      type={type}
      disabled={disabled}
      className={clsx(
        style.default,
        {
          [style.normal]: size === 'normal',
          [style.medium]: size === 'medium',
        },
        className
      )}
      onClick={onClick}
      data-test-id={getTestId(dataTestId)}
    >
      {loading && <LoaderSvg className={style.loader} />}
      {children}
    </button>
  )
}

export default CommonButton
