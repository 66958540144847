import cookie from 'cookie'
import memoize from 'memoize-one'

export const isQAModeOn = memoize(() => {
  const cookies = cookie.parse(document.cookie)
  return !!cookies.qa && cookies.qa.toLowerCase() === 'true'
})
const getTestId = memoize((testId: string) => {
  return isQAModeOn() ? testId : undefined
})
export default getTestId
