import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import Map, { FullscreenControl, MapRef, NavigationControl } from 'react-map-gl'
import { TOKEN_MAPBOX } from 'utils/constants'
import { STYLES } from 'utils/constants'
import { IMapboxElement } from 'utils/interfaces'

import OverlayControl, { IOverlayItem } from './OverlayControl/OverlayControl'
import RulerControl from './RulerControl/RulerControl'

interface MapboxProps {
  onMapMoved?: () => void
  onMapInited?: () => void
  children?: React.ReactNode
  overlayItems?: IOverlayItem[]
  disableRuler?: boolean
}

const MapboxCommon = React.forwardRef<IMapboxElement, MapboxProps>((props, ref) => {
  const mapRef = useRef<MapRef>(null)
  const [mapStyle, setMapStyle] = useState<string>(STYLES.VECTOR)
  const [inited, setInited] = useState(false)

  const initMap = () => {
    props.onMapInited && props.onMapInited()
  }

  const map = useMemo(() => {
    if (inited) {
      return mapRef?.current?.getMap()
    }
  }, [inited])

  useEffect(() => {
    // Будет ли это работать? Надо уточнить
    const map = mapRef?.current?.getMap()
    props.onMapMoved && map?.on('moveend', props.onMapMoved)
    return () => {
      props.onMapMoved && map?.off('moveend', props.onMapMoved)
    }
  }, [props.onMapMoved])

  const onStyleChanged = () => {
    setMapStyle(mapStyle === STYLES.SATELLITE ? STYLES.VECTOR : STYLES.SATELLITE)
  }

  useImperativeHandle(ref, () => ({
    getMap() {
      return mapRef?.current?.getMap()
    },
  }))

  const onMapLoad = useCallback(() => {
    initMap()
    setInited(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Map
      ref={mapRef}
      onLoad={onMapLoad}
      initialViewState={{
        longitude: -122.4,
        latitude: 37.8,
        zoom: 14,
      }}
      mapStyle={mapStyle}
      mapboxAccessToken={TOKEN_MAPBOX}
      attributionControl={false}
    >
      {props.children}
      <NavigationControl showCompass={false} position={'bottom-right'} />
      <FullscreenControl position={'bottom-right'} />
      <OverlayControl mapStyle={mapStyle} onStyleChanged={onStyleChanged} overlayItems={props.overlayItems} />
      {!props.disableRuler && map && <RulerControl map={map} />}
    </Map>
  )
})

export default MapboxCommon
