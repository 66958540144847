import { FeatureCollection, Geometry } from 'geojson'
import { useMemo } from 'react'
import { FillLayer, Layer, LineLayer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { selectMissionTempVisualSettings } from 'store/selectors/mission'
import { ICleaningZone } from 'utils/interfaces'

const CleaningZonesOnMap = ({ cleaningZones }: { cleaningZones: ICleaningZone[] }) => {
  const cleaningZonesFeatureCollections: FeatureCollection<Geometry> = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: cleaningZones,
    }
  }, [cleaningZones])
  const visualSettings = useSelector(selectMissionTempVisualSettings)

  const cleaningZonesFillLayer = useMemo<FillLayer>(() => {
    return {
      id: 'gl-draw-polygon-fill-cleaning-zone',
      type: 'fill',
      paint: {
        'fill-color': visualSettings.clean_fill_color,
        'fill-opacity': visualSettings.clean_opacity,
      },
    }
  }, [visualSettings])

  const cleaningZonesOutlineLayer = useMemo<LineLayer>(() => {
    return {
      id: 'gl-draw-polygon-outline-cleaning-zone',
      type: 'line',
      paint: {
        'line-color': visualSettings.clean_border_color,
        'line-opacity': 1,
        'line-width': visualSettings.clean_width,
      },
    }
  }, [visualSettings])

  return (
    <Source id="cleaning-zones" type="geojson" data={cleaningZonesFeatureCollections}>
      <Layer {...cleaningZonesFillLayer} />
      <Layer {...cleaningZonesOutlineLayer} />
    </Source>
  )
}

export default CleaningZonesOnMap
