import Box from 'components/Box/Box'
import Title from 'components/Title/Title'
import { Polygon } from 'geojson'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectCleaningZones,
  selectMission,
  selectMissionCalculatingStatus,
  selectRestrictZones,
} from 'store/selectors/mission'
import store from 'store/store'
import { missionThunks } from 'store/thunks/mission'
import {
  MISSION_CALCULATING_STATUSES,
  MISSION_PAGE_MODES,
  MISSION_ZONE_TYPES,
  TIMEOUT_FETCHING_STATUS,
} from 'utils/constants'

import CalculatingLoader from './CalculatingLoader/CalculatingLoader'
import MissionButtons from './MissionButtons/MissionButtons'
import MissionMap from './MissionMap/MissionMap'
import DrawZoneMode from './Modes/DrawZone/DrawZoneMode'
import ViewZonesMode from './Modes/ViewZone/ViewZonesMode'

import style from './MissionView.module.css'

const MissionView = () => {
  const mission = useSelector(selectMission)
  const cleaningZones = useSelector(selectCleaningZones)
  const restrictZones = useSelector(selectRestrictZones)
  const calculatingStatus = useSelector(selectMissionCalculatingStatus)

  useEffect(() => {
    const interval = setInterval(async () => {
      if (mission.missionInfo) {
        try {
          store.dispatch(missionThunks.fetchCalculationStatus(mission.missionInfo?.id))
        } catch (error) {
          console.log('Unable to get mission status')
        }
      }
    }, TIMEOUT_FETCHING_STATUS)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSaveZoneClicked = async (zoneName: string, zoneDescription: string, zoneType: MISSION_ZONE_TYPES) => {
    if (!mission.tempZone) {
      return
    }
    if (zoneType === MISSION_ZONE_TYPES.CLEANING) {
      store.dispatch(
        missionThunks.createCleaningZone(
          zoneName,
          zoneDescription,
          mission.tempZone.geometry as Polygon,
          mission.tempLaunchPoint?.geometry
        )
      )
    } else if (zoneType === MISSION_ZONE_TYPES.RESTRICT) {
      store.dispatch(missionThunks.createRestrictZone(zoneName, zoneDescription, mission.tempZone.geometry as Polygon))
    }
  }

  const onSaveChangedZoneClicked = async (zoneName: string, zoneDescription: string, zoneType: MISSION_ZONE_TYPES) => {
    if (!mission.tempZone) {
      return
    }
    if (zoneType === MISSION_ZONE_TYPES.CLEANING) {
      store.dispatch(
        missionThunks.editCleaningZone(
          mission.tempZone.properties?.zone_id,
          zoneName,
          zoneDescription,
          mission.tempZone.geometry as Polygon,
          mission.tempLaunchPoint?.geometry
        )
      )
    } else {
      store.dispatch(
        missionThunks.editRestrictZone(
          mission.tempZone.properties?.zone_id,
          zoneName,
          zoneDescription,
          mission.tempZone.geometry as Polygon
        )
      )
    }
  }

  const modeList = {
    [`${MISSION_PAGE_MODES.DRAW_ZONE}`]: <DrawZoneMode onSaveClicked={onSaveZoneClicked} />,
    [`${MISSION_PAGE_MODES.VIEW_ZONES}`]: <ViewZonesMode cleaningZones={cleaningZones} restrictZones={restrictZones} />,
    [`${MISSION_PAGE_MODES.EDIT_ZONE}`]: (
      <DrawZoneMode onSaveClicked={onSaveChangedZoneClicked} zone={mission.editingZone} />
    ),
  }

  return (
    <>
      <CalculatingLoader visible={calculatingStatus === MISSION_CALCULATING_STATUSES.CALCULATING} />
      <>
        <Title text={mission.missionInfo?.name || ''} />
        <div className={style.main}>
          <div className={style.leftBlock}>
            <MissionButtons />
            <Box className={style.box}>{modeList[mission.mode]}</Box>
          </div>
          <div className={style.map}>
            <MissionMap />
          </div>
        </div>
      </>
    </>
  )
}

export default MissionView
