import { Tabs } from '@mui/material'
import { StyledTab } from 'components/StyledTab/StyledTab'
import TabPanel from 'components/TabPanel/TabPanel'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import RobotControl from './RobotControl/RobotControl'
import RobotDetails from './RobotDetails/RobotDetails'

const RobotLeftBlock = () => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  return (
    <div className="flex-column">
      <Tabs
        value={tab}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        sx={{ height: 45, minHeight: 45 }}
      >
        <StyledTab
          label={t('robot.details')}
          sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0, maxWidth: 185 }}
          disableRipple
        />
        {/* <StyledTab
          label={t('robot.events_log')}
          sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0 }}
          disableRipple
        />*/}
        <StyledTab
          label={t('robot.control')}
          sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0, maxWidth: 185 }}
          disableRipple
        />
      </Tabs>
      <TabPanel value={tab} index={0} disablePadding={true}>
        <RobotDetails />
      </TabPanel>
      <TabPanel value={tab} index={1} disablePadding={true}>
        <RobotControl />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        Events log
      </TabPanel>
    </div>
  )
}

export default RobotLeftBlock
