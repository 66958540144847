import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import RadioButton from 'components/RadioButton/RadioButton'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectMissionTempZone, selectSavingZoneProgress } from 'store/selectors/mission'
import store from 'store/store'
import { missionThunks } from 'store/thunks/mission'
import { MISSION_ZONE_TYPES, PROGRESS_TYPES } from 'utils/constants'
import { IZone } from 'utils/interfaces'

import LaunchPointSettings from './LaunchPointSettings/LaunchPointSettings'

import style from './ZoneDetails.module.css'

const ZoneDetail = ({
  onSaveClicked,
  zone,
}: {
  onSaveClicked: (zoneName: string, zoneDescription: string, zoneType: MISSION_ZONE_TYPES) => void
  zone?: IZone
}) => {
  const { t } = useTranslation()
  const [selectedZoneType, setSelectedZoneType] = useState(zone?.properties?.zone_type || MISSION_ZONE_TYPES.CLEANING)
  const [name, setName] = useState(zone?.properties?.name || 'New Zone')
  const [description, setDescription] = useState(zone?.properties?.description || '')
  const tempZone = useSelector(selectMissionTempZone)
  const savingProgress = useSelector(selectSavingZoneProgress)

  const changeZoneType = useCallback((zoneType: MISSION_ZONE_TYPES) => {
    setSelectedZoneType(zoneType)
  }, [])

  const onSave = () => {
    onSaveClicked(name, description, selectedZoneType)
  }

  const onCancelClicked = () => {
    store.dispatch(missionThunks.cancelEditingZone())
  }

  const savingProgressInWork = useMemo(() => {
    return savingProgress === PROGRESS_TYPES.WORK
  }, [savingProgress])

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className="text-14-400">{t('zone_details.select_type')}</div>
        <div className={style.radioButtons}>
          <div className={style.radioButton}>
            <RadioButton
              checked={selectedZoneType === MISSION_ZONE_TYPES.CLEANING}
              label={t('zone_details.type_cleaning')}
              onChange={() => changeZoneType(MISSION_ZONE_TYPES.CLEANING)}
              disabled={!!zone}
            />
          </div>
          <div className={style.radioButton}>
            <RadioButton
              checked={selectedZoneType === MISSION_ZONE_TYPES.RESTRICT}
              label={t('zone_details.type_restrict')}
              onChange={() => changeZoneType(MISSION_ZONE_TYPES.RESTRICT)}
              disabled={!!zone}
            />
          </div>
        </div>
        <TextFieldDescribed
          className={style.textfield}
          description={t('zone_details.zone_name')}
          defaultValue={name}
          placeholder={t('zone_details.zone_name_placeholder')}
          onChange={e => setName(e.target.value)}
        />
        <TextFieldDescribed
          className={style.textfield}
          description={t('zone_details.zone_description')}
          defaultValue={description}
          placeholder={t('zone_details.zone_description_placeholder')}
          onChange={e => setDescription(e.target.value)}
        />
        {selectedZoneType === MISSION_ZONE_TYPES.CLEANING && (
          <div>
            <div className={clsx('text-14-400', style.subtitle)}>{t('zone_details.start_point')}</div>
            <LaunchPointSettings />
          </div>
        )}
      </div>
      <div className={style.buttons}>
        <Button.Outlined className={style.button} onClick={onCancelClicked} disabled={savingProgressInWork}>
          {t('common.cancel')}
        </Button.Outlined>
        <Button.Standart
          className={style.button}
          onClick={onSave}
          disabled={(!zone && !tempZone) || savingProgressInWork}
          loading={savingProgressInWork}
        >
          {t('common.save')}
        </Button.Standart>
      </div>
    </div>
  )
}

export default ZoneDetail
