import RobotImage from '../RobotImage/RobotImage'
import { useSelector } from 'react-redux'
import { selectRobot } from 'store/selectors/robot'

import RobotAdditionalInfo from './RobotAdditionalInfo/RobotAdditionalInfo'
import RobotInfo from './RobotInfo/RobotInfo'
import RobotMissionUploading from './RobotMissionUploading/RobotMissionUploading'

import style from './RobotDetails.module.css'

const RobotDetails = () => {
  const robot = useSelector(selectRobot)

  return (
    <div className={style.root}>
      <RobotImage />
      <div className={style.tableWrapper}>
        <RobotMissionUploading robotId={robot.robotInfo?.id} />
        <RobotInfo />
      </div>
      <RobotAdditionalInfo />
    </div>
  )
}

export default RobotDetails
