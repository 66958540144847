import { ReactComponent as ArrowSvg } from 'images/icons/arrow_select.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectRobotTelemetry } from 'store/selectors/robot'

import style from './RobotAdditionalInfo.module.css'

const cleaningEquipmentStates = ['TRANSPORT', 'CLEANING', 'ERROR']

const RobotAdditionalInfo = () => {
  const { t } = useTranslation()
  const telemetry = useSelector(selectRobotTelemetry)
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false)
  return (
    <div>
      <div className={style.additionalInfoHeader}>
        <div>{t('robot.additional_info')}</div>
        <div className={style.showMore} onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>
          {showAdditionalInfo ? t('robot.additional_info_hide') : t('robot.additional_info_show')}
          <ArrowSvg className={style.icon} />
        </div>
      </div>
      {showAdditionalInfo && (
        <>
          <div className={style.row}>
            <div>{t('robot.cleaning_equipment_state')}</div>
            <div>
              {telemetry?.cleaningEquipmentState !== undefined
                ? cleaningEquipmentStates[telemetry?.cleaningEquipmentState]
                : '-'}
            </div>
          </div>
          <div className={style.row}>
            <div>{t('robot.rtk_mode')}</div>
            <div>{telemetry?.rtkMode ? 'TRUE' : 'FALSE'}</div>
          </div>
          <div className={style.row}>
            <div>{t('robot.developer_key')}</div>
            <div>{telemetry?.statusCode}</div>
          </div>
        </>
      )}
    </div>
  )
}

export default RobotAdditionalInfo
