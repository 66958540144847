import clsx from 'clsx'
import getTestId from 'utils/getTestId'

import style from './Title.module.css'

function Title({
  text,
  dataTestId = 'title',
  className = '',
}: {
  text: string
  dataTestId?: string
  className?: string
}) {
  return (
    <div data-test-id={getTestId(dataTestId)} className={clsx(style.title, className)}>
      {text}
    </div>
  )
}

export default Title
