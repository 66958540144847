import { AccountApi } from 'api/AccountApi'
import { WhoAmI } from 'api/schema'
import { createContext, useContext } from 'react'
import { Updater, useImmer } from 'use-immer'
import { LOGIN_ERROR_TYPES, PROGRESS_TYPES } from 'utils/constants'

interface IAccountState {
  loginProgress: PROGRESS_TYPES
  restorePasswordProgress: PROGRESS_TYPES
  loginProgressError: LOGIN_ERROR_TYPES
  userLoggedIn: boolean
  id: number
  email?: string
  firstName?: string
  lastName?: string
  isSuperuser: boolean
  isStaff: boolean
  isActive: boolean
}

interface IAccountActions {
  signin: (login: string, password: string) => Promise<any>
  signout: () => Promise<any>
  fetchUser: () => Promise<any>
  restorePassword: (email: string) => Promise<any>
}

export const initialAccountState: IAccountState = {
  loginProgress: PROGRESS_TYPES.IDLE,
  restorePasswordProgress: PROGRESS_TYPES.IDLE,
  loginProgressError: LOGIN_ERROR_TYPES.NONE,
  userLoggedIn: false,
  id: 0,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  isSuperuser: false,
  isStaff: false,
  isActive: false,
}

export const UserProviderContext = createContext<{
  state: IAccountState
  setState: Updater<IAccountState>
  actions: IAccountActions
} | null>(null)

UserProviderContext.displayName = 'User'

export const useUser = () => useContext(UserProviderContext)

export const UserProvider = ({ initialState, children }: { initialState: IAccountState; children: JSX.Element }) => {
  // const [state, setState] = useState(initialState)
  const [state, setState] = useImmer(initialState)

  // const navigate = useNavigate()
  // actions.fetchUser(state, setState, navigate);
  const actions: IAccountActions = {
    signin: async (login: string, password: string) => {
      try {
        setState((draft: IAccountState) => {
          draft.userLoggedIn = false
          draft.loginProgress = PROGRESS_TYPES.WORK
          draft.loginProgressError = LOGIN_ERROR_TYPES.NONE
        })
        const data = await AccountApi.login(login, password)
        if ((data as any).status === 'error') {
          setState({
            ...state,
            userLoggedIn: false,
            loginProgress: PROGRESS_TYPES.ERROR,
            loginProgressError: LOGIN_ERROR_TYPES.INVALID_EMAIL_OR_PASSWORD,
          })
        } else {
          const accountState = normalizeAccount(data)
          setState({ ...accountState, userLoggedIn: true, loginProgress: PROGRESS_TYPES.SUCCESS })
        }
      } catch (error) {
        setState({
          ...state,
          userLoggedIn: false,
          loginProgress: PROGRESS_TYPES.ERROR,
          loginProgressError: LOGIN_ERROR_TYPES.ERROR,
        })
      }
    },
    signout: async () => {
      try {
        await AccountApi.logout()
        setState({ ...initialAccountState, userLoggedIn: false })
      } catch (error) {
        setState({ ...initialAccountState, userLoggedIn: false })
      }
    },
    fetchUser: async () => {
      const accountState = await getInitialAccountState()
      setState({ ...accountState })
    },
    restorePassword: async (email: string) => {
      try {
        setState((draft: IAccountState) => {
          draft.restorePasswordProgress = PROGRESS_TYPES.WORK
        })
        await AccountApi.restorePassword(email)
        setState((draft: IAccountState) => {
          draft.restorePasswordProgress = PROGRESS_TYPES.SUCCESS
        })
      } catch (error) {
        setState((draft: IAccountState) => {
          draft.restorePasswordProgress = PROGRESS_TYPES.ERROR
        })
      }
    },
  }

  return (
    <UserProviderContext.Provider value={{ state, setState, actions }}>
      {children ? children : ''}
    </UserProviderContext.Provider>
  )
}

const normalizeAccount = (accountRaw: WhoAmI): IAccountState => {
  return {
    id: accountRaw.id,
    email: accountRaw.email,
    restorePasswordProgress: PROGRESS_TYPES.IDLE,
    loginProgress: PROGRESS_TYPES.SUCCESS,
    loginProgressError: LOGIN_ERROR_TYPES.NONE,
    userLoggedIn: true,
    firstName: accountRaw.first_name,
    lastName: accountRaw.last_name,
    isSuperuser: accountRaw.is_superuser || false,
    isStaff: accountRaw.is_staff || false,
    isActive: accountRaw.is_active || false,
  }
}

export const getInitialAccountState = async (): Promise<IAccountState> => {
  try {
    const data = await AccountApi.fetchUser()
    return normalizeAccount(data)
  } catch (error) {
    return initialAccountState
  }
}
