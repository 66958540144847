import { useUser } from 'hooks/user'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import getTestId from 'utils/getTestId'

import style from './UserMenu.module.css'

const UserMenu = React.forwardRef<HTMLDivElement | null>((props, ref) => {
  const { t } = useTranslation()
  const user = useUser()

  const onLogoutClick = useCallback(() => {
    user?.actions.signout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={ref} className={style.menu}>
      {/* <div
        className={style.item}
        onClick={() => {
          console.log('Settings')
        }}
        data-test-id={getTestId('userMenuSetting')}
      >
        {t('header.profile')}
      </div> */}
      <div className={style.item} onClick={onLogoutClick} data-test-id={getTestId('userMenuLogout')}>
        {t('header.logout')}
      </div>
    </div>
  )
})

export default UserMenu
