import RobotOnMap from 'components/RobotOnMap/RobotOnMap'
import MissionMap from 'pages/Mission/MissionView/MissionMap/MissionMap'
import { useRef } from 'react'
import { IMapboxElement } from 'utils/interfaces'

const MissionControlMap = () => {
  const mapRef = useRef<IMapboxElement>(null)

  return (
    <MissionMap ref={mapRef}>
      <RobotOnMap map={mapRef.current?.getMap()} />
    </MissionMap>
  )
}

export default MissionControlMap
