import { InputAdornment, TextField } from '@mui/material'
import clsx from 'clsx'
import { ReactNode } from 'react'

import style from './TextFieldDescribed.module.css'

const TextFieldDescribed = ({
  description,
  error = '',
  hasError = false,
  defaultValue,
  placeholder,
  disabled = false,
  rightLabel,
  type = 'text',
  className,
  onChange,
  children,
  value,
}: {
  description: string
  error?: string
  hasError?: boolean
  defaultValue?: string | number
  value?: string | number
  placeholder: string
  disabled?: boolean
  type?: string
  rightLabel?: string
  className?: string
  onChange: (e: any) => void
  children?: ReactNode
}) => {
  return (
    <div className={className}>
      <div className={clsx('text-14-400', style.subtitle)}>{description}</div>
      <div className={style.flexRow}>
        <TextField
          variant="outlined"
          placeholder={placeholder}
          fullWidth
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          disabled={disabled}
          type={type}
          sx={{
            '& fieldset': {
              borderRadius: '0px',
              border: hasError ? 'solid 1px #dc664d' : 'solid 1px #DADDE5',
            },
            '& .MuiInputBase-root': {
              height: '40px',
              fontSize: '14px',
            },
          }}
          InputProps={{
            endAdornment: children && <InputAdornment position="end">{children}</InputAdornment>,
          }}
        />
        {rightLabel && <div className={style.rightLabel}>{rightLabel}</div>}
      </div>
      {hasError ? <div className={style.error}>{error}</div> : null}
    </div>
  )
}

export default TextFieldDescribed
