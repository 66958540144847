import clsx from 'clsx'
import { useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import { STYLES } from 'utils/constants'

import style from './OverlayControl.module.css'

export interface IOverlayItem {
  onClick: () => void
  active: boolean
  disabled: boolean
  content: React.ReactNode
}

const PopoverContent = ({
  mapStyle,
  onStyleChanged,
  overlayItems,
}: {
  mapStyle: string
  onStyleChanged: () => void
  overlayItems?: IOverlayItem[]
}) => {
  const onVectorStyleClick = () => {
    if (mapStyle !== STYLES.VECTOR) {
      onStyleChanged()
    }
  }
  const onSatelliteStyleClick = () => {
    if (mapStyle !== STYLES.SATELLITE) {
      onStyleChanged()
    }
  }
  return (
    <div className={style.popover}>
      <div className={style.popoverHeader}>Choose map layers</div>

      <div
        className={clsx(style.popoverItem, { [style.popoverItemActive]: mapStyle === STYLES.VECTOR })}
        onClick={onVectorStyleClick}
      >
        Vector map
      </div>
      <div
        className={clsx(style.popoverItem, { [style.popoverItemActive]: mapStyle === STYLES.SATELLITE })}
        onClick={onSatelliteStyleClick}
      >
        Satellite
      </div>
      <div className={style.popoverSeparator}></div>
      {overlayItems?.map((item, index) => (
        <div
          key={index}
          onClick={item.onClick}
          className={clsx(style.popoverItem, {
            [style.popoverItemActive]: item.active && !item.disabled,
            [style.popoverItemDisabled]: item.disabled,
          })}
        >
          {item.content}
        </div>
      ))}
    </div>
  )
}

const OverlayControl = ({
  mapStyle,
  onStyleChanged,
  overlayItems,
}: {
  mapStyle: string
  onStyleChanged: () => void
  overlayItems?: IOverlayItem[]
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const onClickOutside = () => {
    setIsPopoverOpen(false)
  }

  const onClick = () => {
    setIsPopoverOpen(!isPopoverOpen)
  }

  return (
    <Popover
      onClickOutside={onClickOutside}
      isOpen={isPopoverOpen}
      positions={['left']} // preferred positions by priority
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#fff'}
          arrowSize={12}
          arrowStyle={{ zIndex: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <PopoverContent mapStyle={mapStyle} onStyleChanged={onStyleChanged} overlayItems={overlayItems} />
        </ArrowContainer>
      )}
    >
      <div className={style.root} onClick={onClick}>
        <button className={style.button}>
          <span className={clsx('mapboxgl-ctrl-icon', style.icon)} title="Layers" />
        </button>
      </div>
    </Popover>
  )
}

export default OverlayControl
