import { CircleLayer, Layer, Source } from 'react-map-gl'
import { IPoint } from 'utils/interfaces'

const cleaningRouteFinishPointStyle1: CircleLayer = {
  id: 'mission-finish-point1',
  type: 'circle',
  paint: {
    'circle-radius': 8,
    'circle-color': '#9747FF',
  },
}

const cleaningRouteFinishPointStyle2: CircleLayer = {
  id: 'mission-finish-point2',
  type: 'circle',
  paint: {
    'circle-radius': 7,
    'circle-color': 'white',
  },
}

const cleaningRouteFinishPointStyle3: CircleLayer = {
  id: 'mission-finish-point3',
  type: 'circle',
  paint: {
    'circle-radius': 5,
    'circle-color': '#9747FF',
  },
}

// launch point
const cleaningRouteLaunchPointStyle1: CircleLayer = {
  id: 'mission-start-point',
  type: 'circle',
  paint: {
    'circle-radius': 8,
    'circle-color': 'rgba(151, 71, 255, 1)',
  },
}

const cleaningRouteLaunchPointStyle2: CircleLayer = {
  id: 'mission-start-point-center',
  type: 'circle',
  paint: {
    'circle-radius': 6,
    'circle-color': 'white',
  },
}

const MissionStartFinishPoints = ({ startPoint, finishPoint }: { startPoint?: IPoint; finishPoint?: IPoint }) => {
  return (
    <>
      {startPoint && (
        <Source id="mission-start-point" type="geojson" data={startPoint}>
          <Layer {...cleaningRouteLaunchPointStyle1} />
          <Layer {...cleaningRouteLaunchPointStyle2} />
        </Source>
      )}
      {finishPoint && (
        <Source id="mission-finish-point" type="geojson" data={finishPoint}>
          <Layer {...cleaningRouteFinishPointStyle1} />
          <Layer {...cleaningRouteFinishPointStyle2} />
          <Layer {...cleaningRouteFinishPointStyle3} />
        </Source>
      )}
    </>
  )
}

export default MissionStartFinishPoints
