import clsx from 'clsx'
import { useUser } from 'hooks/user'
import { ReactComponent as LogoSvg } from 'images/logo_main.svg'
import { useTranslation } from 'react-i18next'
import { Link, useMatch } from 'react-router-dom'
import { PATH } from 'utils/constants'

import User from './components/User/User'

import style from '../Header.module.css'

const MainHeader = () => {
  const { t } = useTranslation()
  const tabMissionControl = useMatch(`${PATH.MISSION_CONTROL}/*`)
  const tabRobots = useMatch(`${PATH.ROBOT_LIST}/*`)
  const tabMissionPlanner = useMatch(`/`)
  const user = useUser()

  return (
    <div className={style.header}>
      <Link to={PATH.MISSION_LIST} className={style.logo}>
        <LogoSvg />
      </Link>
      <div className={style.middle}>
        <div className={style.middleLinksBlock}>
          {tabMissionControl && (
            <div className={clsx(style.link, style.noCursor, style.active)}>{t('header.mission_control')}</div>
          )}
          <Link to={PATH.ROBOT_LIST} className={clsx(style.link, { [style.active]: tabRobots })}>
            {t('header.assets')}
          </Link>
          <Link to={PATH.MISSION_LIST} className={clsx(style.link, { [style.active]: tabMissionPlanner })}>
            {t('header.mission_planner')}
          </Link>
        </div>
      </div>
      <div className={style.user}>
        <User email={user?.state.email} />
      </div>
    </div>
  )
}
export default MainHeader
