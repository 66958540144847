import { FeatureCollection, Geometry } from 'geojson'
import { useMemo } from 'react'
import { Layer, Source, SymbolLayer } from 'react-map-gl'
import { ICleaningZone } from 'utils/interfaces'

const textLayer: SymbolLayer = {
  id: 'text-layer',
  type: 'symbol',
  layout: {
    'text-field': ['get', 'name'],
    'text-font': ['Open Sans Regular'],
    'text-offset': [0, 0],
    'text-anchor': 'center',
  },
  paint: {
    'text-halo-color': 'white',
    'text-halo-width': 2,
    'text-halo-blur': 1,
  },
}

const CleaningZonesNamesOnMap = ({ cleaningZones }: { cleaningZones: ICleaningZone[] }) => {
  const cleaningZonesFeatureCollections: FeatureCollection<Geometry> = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: cleaningZones,
    }
  }, [cleaningZones])

  return (
    <Source id="cleaning-zones-names" type="geojson" data={cleaningZonesFeatureCollections}>
      <Layer {...textLayer} />
    </Source>
  )
}

export default CleaningZonesNamesOnMap
