import { FeatureCollection, MultiLineString } from 'geojson'
import { useMemo } from 'react'
import { Layer, LineLayer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { selectMissionTempVisualSettings } from 'store/selectors/mission'
import { IRoute } from 'utils/interfaces'

const CleaningRoutesOnMap = ({ cleaningRoutes }: { cleaningRoutes: IRoute[] }) => {
  const cleaningRoutesFeatureCollections: FeatureCollection<MultiLineString> = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: cleaningRoutes,
    }
  }, [cleaningRoutes])
  const visualSettings = useSelector(selectMissionTempVisualSettings)

  const cleaningRouteStyle = useMemo<LineLayer>(() => {
    return {
      id: 'gl-draw-cleaning-routes',
      type: 'line',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': visualSettings.cleaning_route_color,
        'line-opacity': visualSettings.cleaning_route_opacity,
        'line-width': visualSettings.cleaning_route_width,
      },
    }
  }, [visualSettings])

  return (
    <Source id="cleaning-routes" type="geojson" data={cleaningRoutesFeatureCollections} tolerance={1}>
      <Layer {...cleaningRouteStyle} />
    </Source>
  )
}

export default CleaningRoutesOnMap
