import { RobotApi } from 'api/RobotApi'
import clsx from 'clsx'
import Box from 'components/Box/Box'
import MapboxCommon from 'components/MapboxMap/MapboxCommon'
import RobotOnMap from 'components/RobotOnMap/RobotOnMap'
import Title from 'components/Title/Title'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RobotActions } from 'store/actions/robot'
import { selectRobot } from 'store/selectors/robot'
import { TIMEOUT_FETCHING_STATUS } from 'utils/constants'
import { IMapboxElement } from 'utils/interfaces'

import RobotLeftBlock from './RobotLeftBlock/RobotLeftBlock'

import style from './RobotView.module.css'

const RobotView = () => {
  const { t } = useTranslation()
  const robot = useSelector(selectRobot)
  const mapRef = useRef<IMapboxElement>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const interval = setInterval(async () => {
      if (robot.robotInfo) {
        const newRobot = await RobotApi.fetchRobot(robot.robotInfo.id)
        dispatch(RobotActions.setRobotInfo(newRobot))
      }
    }, TIMEOUT_FETCHING_STATUS)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robot.robotInfo?.id])

  return (
    <>
      <div className={style.titleBlock}>
        <Title text={robot.robotInfo?.name || ''} />
        <div className={clsx('text-16-300', style.titleId)}>
          {t('robot.serial_number')}: {robot.robotInfo?.serialNumber}
        </div>
      </div>
      <div className={style.main}>
        <div className={style.leftBlock}>
          <Box className={style.box}>
            <RobotLeftBlock />
          </Box>
        </div>
        <div className={style.map}>
          <MapboxCommon ref={mapRef}>
            <RobotOnMap map={mapRef.current?.getMap()} />
          </MapboxCommon>
        </div>
      </div>
    </>
  )
}
export default RobotView
