import { FeatureCollection, LineString } from 'geojson'
import { useMemo } from 'react'
import { Layer, LineLayer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { selectMissionTempVisualSettings } from 'store/selectors/mission'
import { ITrajectory } from 'utils/interfaces'

const TransferRoutesOnMap = ({ transferRoutes }: { transferRoutes: ITrajectory[] }) => {
  const transferRoutesFeatureCollections: FeatureCollection<LineString> = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: transferRoutes,
    }
  }, [transferRoutes])
  const visualSettings = useSelector(selectMissionTempVisualSettings)

  const transferRouteStyle = useMemo<LineLayer>(() => {
    return {
      id: 'transfer-route-style',
      type: 'line',
      layout: {
        'line-cap': 'round',
      },
      paint: {
        'line-width': visualSettings.transfer_route_width,
        'line-color': visualSettings.transfer_route_color,
        'line-opacity': visualSettings.transfer_route_opacity,
      },
    }
  }, [visualSettings])

  const transferRouteHaloStyle = useMemo<LineLayer>(() => {
    return {
      id: 'transfer-route-halo-style',
      type: 'line',
      layout: {
        'line-cap': 'round',
      },
      paint: {
        'line-width': 9,
        'line-color': visualSettings.transfer_route_color,
        'line-opacity': 0.5,
      },
    }
  }, [visualSettings])

  return (
    <Source id="transfer-routes" type="geojson" data={transferRoutesFeatureCollections}>
      <Layer {...transferRouteStyle} />
      <Layer {...transferRouteHaloStyle} />
    </Source>
  )
}

export default TransferRoutesOnMap
