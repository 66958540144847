import { Tabs } from '@mui/material'
import { StyledTab } from 'components/StyledTab/StyledTab'
import TabPanel from 'components/TabPanel/TabPanel'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MISSION_ZONE_TYPES } from 'utils/constants'
import { IZone } from 'utils/interfaces'

import ZoneDetail from './ZoneDetails/ZoneDetails'

const DrawZoneMode = ({
  onSaveClicked,
  zone,
}: {
  onSaveClicked: (zoneName: string, zoneDescription: string, zoneType: MISSION_ZONE_TYPES) => Promise<void>
  zone?: IZone
}) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  return (
    <div className="flex-column">
      <Tabs
        value={tab}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        sx={{ height: 45, minHeight: 45 }}
      >
        <StyledTab
          label={t('mission.zone_details')}
          sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0, maxWidth: 200 }}
          disableRipple
        />
        {/* <StyledTab
          label={t('mission.cleaning_route')}
          sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0 }}
          disableRipple
        />
        <StyledTab
          label={t('mission.transfer')}
          sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0 }}
          disableRipple
        /> */}
      </Tabs>
      <TabPanel value={tab} index={0}>
        <ZoneDetail onSaveClicked={onSaveClicked} zone={zone} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={tab} index={2}>
        Item Three
      </TabPanel>
    </div>
  )
}

export default DrawZoneMode
