import clsx from 'clsx'
import getTestId from 'utils/getTestId'

import CommonButton, { TButton } from './CommonButton'

import style from '../Button.module.css'

function StandartButton(props: React.PropsWithChildren<TButton>) {
  return (
    <CommonButton
      {...props}
      data-test-id={getTestId(props.dataTestId || 'button-outline')}
      className={clsx(style.standart, props.className)}
    />
  )
}

export default StandartButton
