import { FeatureCollection, Point } from 'geojson'
import { useMemo } from 'react'
import { CircleLayer, Layer, Source } from 'react-map-gl'
import { ILaunchPoint, IRoute } from 'utils/interfaces'

const cleaningRouteFinishPointStyle1: CircleLayer = {
  id: 'gl-draw-cleaning-route-finish-point1',
  type: 'circle',
  paint: {
    'circle-radius': 7,
    'circle-color': '#9747FF',
  },
}

const cleaningRouteFinishPointStyle2: CircleLayer = {
  id: 'gl-draw-cleaning-route-finish-point2',
  type: 'circle',
  paint: {
    'circle-radius': 6,
    'circle-color': 'white',
  },
}

const cleaningRouteFinishPointStyle3: CircleLayer = {
  id: 'gl-draw-cleaning-route-finish-point3',
  type: 'circle',
  paint: {
    'circle-radius': 4,
    'circle-color': '#9747FF',
  },
}

// launch point
const cleaningRouteLaunchPointStyle1: CircleLayer = {
  id: 'gl-draw-cleaning-route-launch-point',
  type: 'circle',
  paint: {
    'circle-radius': 7,
    'circle-color': 'rgba(151, 71, 255, 1)',
  },
}

const cleaningRouteLaunchPointStyle2: CircleLayer = {
  id: 'gl-draw-cleaning-route-launch-point-center',
  type: 'circle',
  paint: {
    'circle-radius': 5,
    'circle-color': 'white',
  },
}

const CleaningPointsOnMap = ({
  cleaningRoutes,
  launchPoints,
}: {
  cleaningRoutes: IRoute[]
  launchPoints: ILaunchPoint[]
}) => {
  const cleaningRoutesFinishPointsFeatureCollections: FeatureCollection<Point> = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: cleaningRoutes.map(cleaningRoute => {
        const lastLineIndex = cleaningRoute.geometry.coordinates.length - 1
        const lastCoordIndex = cleaningRoute.geometry.coordinates[lastLineIndex].length - 1
        return {
          id: cleaningRoute.id + '-finish-point',
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: cleaningRoute.geometry.coordinates[lastLineIndex][lastCoordIndex],
          },
          properties: {},
        }
      }),
    }
  }, [cleaningRoutes])

  const cleaningRoutesLaunchPointsFeatureCollections: FeatureCollection<Point> = useMemo(() => {
    const featureCollection: FeatureCollection<Point> = {
      type: 'FeatureCollection',
      features: [],
    }
    launchPoints.forEach(launchPoint => {
      featureCollection.features.push(launchPoint)
    })
    cleaningRoutes.forEach(cleaningRoute => {
      const launchPointExisting = launchPoints.find(
        launchPoint => launchPoint.properties?.zone_id === cleaningRoute.properties?.zone_id
      )
      if (!launchPointExisting) {
        featureCollection.features.push({
          id: cleaningRoute.id + '-launch-point',
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: cleaningRoute.geometry.coordinates[0][0],
          },
          properties: {},
        })
      }
    })
    return featureCollection
  }, [cleaningRoutes, launchPoints])

  return (
    <>
      <Source id="cleaning-routes-finish-points" type="geojson" data={cleaningRoutesFinishPointsFeatureCollections}>
        <Layer {...cleaningRouteFinishPointStyle1} />
        <Layer {...cleaningRouteFinishPointStyle2} />
        <Layer {...cleaningRouteFinishPointStyle3} />
      </Source>
      <Source id="cleaning-routes-launch-points" type="geojson" data={cleaningRoutesLaunchPointsFeatureCollections}>
        <Layer {...cleaningRouteLaunchPointStyle1} />
        <Layer {...cleaningRouteLaunchPointStyle2} />
      </Source>
    </>
  )
}

export default CleaningPointsOnMap
