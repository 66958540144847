import clsx from 'clsx'

import style from './RadioButton.module.css'

type TRadioButtonProps = {
  label: string
  checked: boolean
  className?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
}

const RadioButton = (props: TRadioButtonProps) => {
  return (
    <label className={style.container}>
      <input type="radio" checked={props.checked} name="radio" onChange={props.onChange} disabled={props.disabled} />
      <span className={props.disabled ? clsx(style.check, style.checkDisabled) : style.check}></span>
      <span className={props.checked && !props.disabled ? style.labelActive : style.labelInactive}>{props.label}</span>
    </label>
  )
}

export default RadioButton
