import { FeatureCollection, Point } from 'geojson'
import RobotOnMapImage from 'images/robot_on_map.png'
import { useEffect, useMemo } from 'react'
import { Layer, Source, SymbolLayer } from 'react-map-gl'
import { ROBOT_NETWORK_STATUSES } from 'utils/constants'
import { getYawFromQuaternion } from 'utils/getYawFromQuaternion'
import { IRobotInfo } from 'utils/interfaces'
import { zoomMapTo } from 'utils/mapUtils'

const layerRobotStyle: SymbolLayer = {
  id: 'robot-image-style',
  type: 'symbol',
  layout: {
    'icon-image': 'robot',
    'icon-anchor': 'center',
    'icon-rotation-alignment': 'map',
    'icon-size': ['interpolate', ['exponential', 2], ['zoom'], 15, 0.6, 22, 3.2],
    'icon-rotate': ['get', 'yaw'],
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': ['get', 'opacity'],
  },
}

const AllRobotsOnMap = ({
  map,
  robots,
  onRobotClicked,
}: {
  map: mapboxgl.Map | undefined
  robots: IRobotInfo[]
  onRobotClicked: (id?: number) => void
}) => {
  const robotsOnMap: FeatureCollection<Point> = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: robots
        .filter(robot => robot.lastPosition)
        .map(robot => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: robot.lastPosition?.coordinates || [],
            },
            properties: {
              opacity: robot.networkStatus === ROBOT_NETWORK_STATUSES.ONLINE ? 1 : 0.7,
              id: robot.id,
              yaw: robot.lastOrientation ? getYawFromQuaternion(robot.lastOrientation) : 0,
            },
          }
        }),
    }
  }, [robots])

  useEffect(() => {
    loadRobotImage(map)
    map?.on('style.load', () => loadRobotImage(map))
    zoomMapTo(map, robotsOnMap.features)
  }, [map, robotsOnMap.features])

  const onRobotClickedOnMap = (e: any) => {
    if (e.features[0]) {
      onRobotClicked(e.features[0].properties.id)
    } else {
      console.warn('Here should be robot, but got nothing')
    }
  }

  const onNothingClicked = (e: any) => {
    // setHighlightedRobot(e?.features && e?.features[0].properties?.id_on_map)'
    onRobotClicked()
  }

  useEffect(() => {
    map?.on('click', onNothingClicked)
    map?.on('click', 'robot-image-style', onRobotClickedOnMap)
    return () => {
      map?.off('click', onNothingClicked)
      map?.off('click', 'robot-image-style', onRobotClickedOnMap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  const loadRobotImage = (map: mapboxgl.Map | undefined) => {
    if (map?.hasImage('robot')) {
      return
    }
    map?.loadImage(RobotOnMapImage, (error, image) => {
      if (error || !image) {
        return
      }
      if (map?.hasImage('robot')) {
        return
      }
      map?.addImage('robot', image)
    })
  }
  return (
    <>
      {robotsOnMap && (
        <Source id="robot" type="geojson" data={robotsOnMap}>
          <Layer {...layerRobotStyle} />
        </Source>
      )}
    </>
  )
}

export default AllRobotsOnMap
