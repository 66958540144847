import clsx from 'clsx'
import dateFnsFormat from 'date-fns/format'
import { useTranslation } from 'react-i18next'
import { PATH } from 'utils/constants'
import { IMissionInfo } from 'utils/interfaces'

import style from './MissionsList.module.css'

const MissionModalItem = ({
  mission,
  selected,
  onSelect,
}: {
  mission: IMissionInfo
  selected: boolean
  onSelect: () => void
}) => {
  const { t } = useTranslation()

  return (
    <div className={selected ? clsx(style.tableItem, style.selected) : style.tableItem} onClick={onSelect}>
      <span className={style.missionName}>
        {mission.name}
        <a className={style.link} href={`${PATH.MISSION}${mission.id}`} target="_blank" rel="noreferrer">
          show
        </a>
      </span>
      <span className={style.centered}>{t('mission_status.' + mission.status)}</span>
      <span>{dateFnsFormat(new Date(mission.createdAt), 'dd.MM.yyyy HH:mm')}</span>
    </div>
  )
}

const MissionsList = ({
  missions,
  selectedMission,
  onSelect,
}: {
  missions: IMissionInfo[]
  selectedMission: IMissionInfo | undefined
  onSelect: (mission: IMissionInfo) => void
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={style.tableHeader}>
        <span>{t('missions.name')}</span>
        <span>{t('missions.status')}</span>
        <span>{t('missions.createdAt')}</span>
      </div>
      <div className={style.table}>
        {missions.map(mission => (
          <MissionModalItem
            key={mission.id}
            mission={mission}
            selected={mission === selectedMission}
            onSelect={() => onSelect(mission)}
          />
        ))}
      </div>
    </>
  )
}

export default MissionsList
