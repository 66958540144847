import { ReactComponent as LogoSvg } from 'images/logo_main.svg'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'

import style from '../Header.module.css'

const PublicHeader = () => {
  const navigate = useNavigate()

  return (
    <div className={style.header}>
      <div className={style.logo} onClick={() => navigate(PATH.MISSION_LIST)}>
        <LogoSvg />
      </div>
    </div>
  )
}

export default PublicHeader
