import { FeatureCollection, Point } from 'geojson'
import GarageOnMapImage from 'images/garage_on_map.png'
import { useCallback, useEffect, useMemo } from 'react'
import { Layer, Source, SymbolLayer } from 'react-map-gl'

const layerGarageStyle: SymbolLayer = {
  id: 'garage-image-style',
  type: 'symbol',
  layout: {
    'icon-image': 'garage',
    'icon-anchor': 'center',
    'icon-rotation-alignment': 'map',
    'icon-size': 1,
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': 1,
  },
}

export default function GarageOnMap({
  map,
  garages,
  onPointChanged,
}: {
  map: mapboxgl.Map | undefined
  garages: Point[]
  onPointChanged?: (point: Point) => void
}) {
  const garagePointData = useMemo<FeatureCollection<Point>>(() => {
    return {
      type: 'FeatureCollection',
      features: garages?.map(garagePoint => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: garagePoint.coordinates,
          },
          properties: {},
        }
      }),
    }
  }, [garages])

  useEffect(() => {
    loadGarageImage(map)
    map?.on('style.load', () => loadGarageImage(map))
  }, [map])

  const loadGarageImage = (map: mapboxgl.Map | undefined) => {
    if (map?.hasImage('garage')) {
      return
    }
    map?.loadImage(GarageOnMapImage, (error, image) => {
      if (error || !image) {
        console.log('error loading image')
        return
      }
      if (!map?.hasImage('garage')) {
        map?.addImage('garage', image)
      }
    })
  }

  const onMapClicked = useCallback(
    (e: any) => {
      onPointChanged && onPointChanged({ type: 'Point', coordinates: [e.lngLat.lng, e.lngLat.lat] })
    },
    [onPointChanged]
  )

  useEffect(() => {
    map?.on('click', onMapClicked)
    return () => {
      map?.off('click', onMapClicked)
    }
  }, [map, onMapClicked])

  return (
    <Source id="garage" type="geojson" data={garagePointData}>
      <Layer {...layerGarageStyle} />
    </Source>
  )
}
