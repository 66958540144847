import axios, { AxiosError } from 'axios'

type Data = { [key: string]: any }
type Params = { [key: string]: any }

enum METHOD {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
}

interface Options {
  multipart?: boolean
  params?: Params
  data?: Data
  eventEmitter?: (params: any) => void
  transform?: (params: any) => void
  responseType?: 'blob'
}

const baseURL = '/'

const defaultOptions = {
  multipart: false,
  params: undefined,
  data: undefined,
  eventEmitter: undefined,
  transform: undefined,
}

const getHeaders = (multipart: boolean = false) => ({
  'Content-Type': multipart ? 'multipart/form-data' : 'application/json;charset=utf-8',
})

const multipartTransform = (data: Data): FormData => {
  return Object.keys(data)
    .filter(key => Boolean(data[key]))
    .reduce((form, key) => {
      form.append(key, data[key])
      return form
    }, new FormData())
}

class DataError extends Error {
  constructor(status: number, statusText: string, data?: any) {
    super(`${status} ${statusText}`)
    Object.setPrototypeOf(this, Object.assign({}, DataError.prototype, { status, statusText, data }))
  }
}

const api = (method: METHOD, url: string, options: Options | undefined = defaultOptions) => {
  const source = axios.CancelToken.source()
  const multipart = options.multipart
  const data = options.data
  const params = options.params
  const eventEmitter = options.eventEmitter
  const transform = options.transform

  const promise: any = axios({
    url,
    method,
    baseURL,
    params,
    data,
    headers: getHeaders(multipart),
    cancelToken: source.token,
    ...(multipart && { transformRequest: [data => multipartTransform(data)] }),
    ...(transform && { transformResponse: [data => transform(data)] }),
    ...(eventEmitter && { onUploadProgress: eventEmitter, onDownloadProgress: eventEmitter }),
  })
    .then(function (response) {
      return response
    })
    .catch(function (err: AxiosError) {
      if (err && err.response) {
        throw new DataError(err.response.status, err.response.statusText, err.response.data)
      }
    })

  return promise
}

const API = {
  axios,
  [METHOD.GET]: (url: string, options?: Options) => api(METHOD.GET, url, options),
  [METHOD.POST]: (url: string, options?: Options) => api(METHOD.POST, url, options),
  [METHOD.DELETE]: (url: string, options?: Options) => api(METHOD.DELETE, url, options),
  [METHOD.PATCH]: (url: string, options?: Options) => api(METHOD.PATCH, url, options),
  [METHOD.PUT]: (url: string, options?: Options) => api(METHOD.PUT, url, options),
}

export default API
