import { Button } from 'components/Button/Button'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MissionActions } from 'store/actions/mission'
import {
  selectMapMode,
  selectMissionEditingZone,
  selectMissionTempLaunchPoint,
  selectMissionTempZone,
} from 'store/selectors/mission'
import { MISSION_MAP_DRAWING_MODES } from 'utils/constants'

import style from './LaunchPointSettings.module.css'

enum MODES {
  NO_POINT,
  DRAWING,
  DRAWN,
}

const LaunchPointSettings = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // const [isDrawing, setIsDrawing] = useState(false)
  const editingZone = useSelector(selectMissionEditingZone)
  const tempZone = useSelector(selectMissionTempZone)
  const tempLaunchPoint = useSelector(selectMissionTempLaunchPoint)
  const mapMode = useSelector(selectMapMode)

  const mode = useMemo(() => {
    if (!tempLaunchPoint) {
      if (mapMode === MISSION_MAP_DRAWING_MODES.ADD_ZONE_LAUNCH_POINT) {
        return MODES.DRAWING
      }
      return MODES.NO_POINT
    }
    return MODES.DRAWN
  }, [mapMode, tempLaunchPoint])

  const onAddPointClick = () => {
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.ADD_ZONE_LAUNCH_POINT))
  }

  const onCancelClick = () => {
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE))
  }

  const onDeleteClick = () => {
    // if (editingLaunchPoint && editingZone) {
    //   dispatch(MissionActions.setEditingLaunchPoint(undefined))
    // }
    dispatch(MissionActions.setTempLaunchPoint(undefined))
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE))
  }

  const buttons = {
    [`${MODES.NO_POINT}`]: (
      <Button.Outlined disabled={!editingZone && !tempZone} className={style.button} onClick={onAddPointClick}>
        {t('zone_details.start_point_add_point')}
      </Button.Outlined>
    ),
    [`${MODES.DRAWING}`]: (
      <Button.Outlined className={style.button} onClick={onCancelClick}>
        {t('zone_details.start_point_cancel_drawing')}
      </Button.Outlined>
    ),
    [`${MODES.DRAWN}`]: (
      <Button.Outlined className={style.button} onClick={onDeleteClick}>
        {t('zone_details.start_point_delete_point')}
      </Button.Outlined>
    ),
  }

  const statuses = {
    [`${MODES.NO_POINT}`]: t('zone_details.start_point_status'),
    [`${MODES.DRAWING}`]: t('zone_details.start_point_status'),
    [`${MODES.DRAWN}`]: t('zone_details.start_point_distance'),
  }

  return (
    <>
      <div className={style.flex}>
        <div className={style.description}>{statuses[mode]}</div>
        {buttons[mode]}
      </div>
      {mode === MODES.NO_POINT && (
        <div className={style.descriptionStart}>{t('zone_details.start_point_description')}</div>
      )}
    </>
  )
}

export default LaunchPointSettings
