import ColorPicker from 'components/ColorPicker/ColorPicker'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import { ReactComponent as ArrowSvg } from 'images/icons/arrow_select.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MissionActions } from 'store/actions/mission'
import { selectMissionTempVisualSettings } from 'store/selectors/mission'

import style from './VisualSettings.module.css'

const VisualSettings = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const tempVisualSettings = useSelector(selectMissionTempVisualSettings)

  return (
    <>
      <div className={style.additionalInfoHeader}>
        <div>{t('visual_settings.title')}</div>
        <div className={style.showMore} onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? t('common.hide') : t('common.show')}
          <ArrowSvg className={style.icon} />
        </div>
      </div>
      {isOpen && (
        <>
          <div className={style.visualSettingsRow}>
            <TextFieldDescribed
              className={style.textfieldColor}
              description="Clean fill"
              placeholder=""
              value={tempVisualSettings.clean_fill_color}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({ ...tempVisualSettings, clean_fill_color: e.target.value })
                )
              }
            >
              <ColorPicker
                color={tempVisualSettings.clean_fill_color}
                onChange={colorHex => {
                  dispatch(MissionActions.setTempVisualSettings({ ...tempVisualSettings, clean_fill_color: colorHex }))
                }}
              />
            </TextFieldDescribed>
            <TextFieldDescribed
              className={style.textfieldColor}
              description="Clean border"
              placeholder=""
              value={tempVisualSettings.clean_border_color}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({ ...tempVisualSettings, clean_border_color: e.target.value })
                )
              }
            >
              <ColorPicker
                color={tempVisualSettings.clean_border_color}
                onChange={colorHex => {
                  dispatch(
                    MissionActions.setTempVisualSettings({ ...tempVisualSettings, clean_border_color: colorHex })
                  )
                }}
              />
            </TextFieldDescribed>
            <TextFieldDescribed
              className={style.textfieldNumber}
              description="Clean width"
              placeholder=""
              value={tempVisualSettings.clean_width}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({
                    ...tempVisualSettings,
                    clean_width: parseFloat(e.target.value),
                  })
                )
              }
            >
              px
            </TextFieldDescribed>
            <TextFieldDescribed
              className={style.textfieldNumber}
              description="Clean opacity"
              placeholder=""
              value={tempVisualSettings.clean_opacity * 100}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({
                    ...tempVisualSettings,
                    clean_opacity: parseFloat(e.target.value) / 100,
                  })
                )
              }
            >
              %
            </TextFieldDescribed>
          </div>
          <div className={style.visualSettingsRow}>
            <TextFieldDescribed
              className={style.textfieldColor}
              description="Restrict fill"
              placeholder=""
              value={tempVisualSettings.restrict_fill_color}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({ ...tempVisualSettings, restrict_fill_color: e.target.value })
                )
              }
            >
              <ColorPicker
                color={tempVisualSettings.restrict_fill_color}
                onChange={colorHex => {
                  dispatch(
                    MissionActions.setTempVisualSettings({ ...tempVisualSettings, restrict_fill_color: colorHex })
                  )
                }}
              />
            </TextFieldDescribed>
            <TextFieldDescribed
              className={style.textfieldColor}
              description="Restrict border"
              placeholder=""
              value={tempVisualSettings.restrict_border_color}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({ ...tempVisualSettings, restrict_border_color: e.target.value })
                )
              }
            >
              <ColorPicker
                color={tempVisualSettings.restrict_border_color}
                onChange={colorHex => {
                  dispatch(
                    MissionActions.setTempVisualSettings({ ...tempVisualSettings, restrict_border_color: colorHex })
                  )
                }}
              />
            </TextFieldDescribed>
            <TextFieldDescribed
              className={style.textfieldNumber}
              description="Restrict width"
              placeholder=""
              value={tempVisualSettings.restrict_width}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({
                    ...tempVisualSettings,
                    restrict_width: parseFloat(e.target.value),
                  })
                )
              }
            >
              px
            </TextFieldDescribed>
            <TextFieldDescribed
              className={style.textfieldNumber}
              description="Restrict opacity"
              placeholder=""
              value={tempVisualSettings.restrict_opacity * 100}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({
                    ...tempVisualSettings,
                    restrict_opacity: parseFloat(e.target.value) / 100,
                  })
                )
              }
            >
              %
            </TextFieldDescribed>
          </div>
          <div className={style.visualSettingsRow}>
            <TextFieldDescribed
              className={style.textfieldColor}
              description="Cleaning route color"
              placeholder=""
              value={tempVisualSettings.cleaning_route_color}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({ ...tempVisualSettings, cleaning_route_color: e.target.value })
                )
              }
            >
              <ColorPicker
                color={tempVisualSettings.cleaning_route_color}
                onChange={colorHex => {
                  dispatch(
                    MissionActions.setTempVisualSettings({ ...tempVisualSettings, cleaning_route_color: colorHex })
                  )
                }}
              />
            </TextFieldDescribed>
            <TextFieldDescribed
              className={style.textfieldNumber}
              description="Cleaning route width"
              placeholder=""
              value={tempVisualSettings.cleaning_route_width}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({
                    ...tempVisualSettings,
                    cleaning_route_width: parseFloat(e.target.value),
                  })
                )
              }
            >
              px
            </TextFieldDescribed>
            <TextFieldDescribed
              className={style.textfieldNumber}
              description="Cleaning route opacity"
              placeholder=""
              value={tempVisualSettings.cleaning_route_opacity * 100}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({
                    ...tempVisualSettings,
                    cleaning_route_opacity: parseFloat(e.target.value) / 100,
                  })
                )
              }
            >
              %
            </TextFieldDescribed>
          </div>
          <div className={style.visualSettingsRow}>
            <TextFieldDescribed
              className={style.textfieldColor}
              description="Transfer route color"
              placeholder=""
              value={tempVisualSettings.transfer_route_color}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({ ...tempVisualSettings, transfer_route_color: e.target.value })
                )
              }
            >
              <ColorPicker
                color={tempVisualSettings.transfer_route_color}
                onChange={colorHex => {
                  dispatch(
                    MissionActions.setTempVisualSettings({ ...tempVisualSettings, transfer_route_color: colorHex })
                  )
                }}
              />
            </TextFieldDescribed>
            <TextFieldDescribed
              className={style.textfieldNumber}
              description="Transfer route width"
              placeholder=""
              value={tempVisualSettings.transfer_route_width}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({
                    ...tempVisualSettings,
                    transfer_route_width: parseFloat(e.target.value),
                  })
                )
              }
            >
              px
            </TextFieldDescribed>
            <TextFieldDescribed
              className={style.textfieldNumber}
              description="Transfer route opacity"
              placeholder=""
              value={tempVisualSettings.transfer_route_opacity * 100}
              disabled={false}
              onChange={e =>
                dispatch(
                  MissionActions.setTempVisualSettings({
                    ...tempVisualSettings,
                    transfer_route_opacity: parseFloat(e.target.value) / 100,
                  })
                )
              }
            >
              %
            </TextFieldDescribed>
          </div>
        </>
      )}
    </>
  )
}

export default VisualSettings
