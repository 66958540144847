import { ClickAwayListener } from '@mui/material'
import clsx from 'clsx'
import CommonButton from 'components/Button/ButtonTypes/CommonButton'
import { ReactComponent as ArrowSvg } from 'images/icons/arrow_select.svg'
import { ReactComponent as DrawZoneSvg } from 'images/icons/draw_zone.svg'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MissionActions } from 'store/actions/mission'
import { selectMapMode, selectMissionFinishPoint, selectMissionStartPoint } from 'store/selectors/mission'
import { MISSION_MAP_DRAWING_MODES, MISSION_PAGE_MODES } from 'utils/constants'

import style from '../MissionButtons.module.css'

enum DROPDOWN_STATES {
  DRAW_ZONE,
  DRAW_START_POINT,
  DRAW_FINISH_POINT,
}

const DrawZoneContent = () => {
  const { t } = useTranslation()

  return (
    <div className={style.complexButtonContent}>
      <div className={style.icon}>
        <DrawZoneSvg />
      </div>
      <div className={style.complexButtonDesc}>
        <span className="text-16-500">{t('mission.draw_zone')}</span>
        <span className="text-12-400">{t('mission.draw_zone_desc')}</span>
      </div>
    </div>
  )
}

const AddStartPointContent = () => {
  const { t } = useTranslation()

  return (
    <div className={style.complexButtonContent}>
      <div className={style.icon} />
      <div className={style.complexButtonDesc}>
        <span className={style.complexButtonTextTitle}>{t('mission.add_start_point')}</span>
        <span className={style.complexButtonTextDesc}>{t('mission.add_start_point_desc')}</span>
      </div>
    </div>
  )
}

const AddFinishPointContent = () => {
  const { t } = useTranslation()

  return (
    <div className={style.complexButtonContent}>
      <div className={style.icon} />
      <div className={style.complexButtonDesc}>
        <span className={style.complexButtonTextTitle}>{t('mission.add_finish_point')}</span>
        <span className={style.complexButtonTextDesc}>{t('mission.add_finish_point_desc')}</span>
      </div>
    </div>
  )
}

export const DrawDropdown = () => {
  const mapMode = useSelector(selectMapMode)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [dropdownState, setDropdownState] = useState(DROPDOWN_STATES.DRAW_ZONE)
  const startPoint = useSelector(selectMissionStartPoint)
  const finishPoint = useSelector(selectMissionFinishPoint)

  const dropdownActive = useMemo(() => {
    return mapMode !== MISSION_MAP_DRAWING_MODES.VIEW_ZONES
  }, [mapMode])

  const onDrawZoneButtonClicked = useCallback(() => {
    setDropdownState(DROPDOWN_STATES.DRAW_ZONE)
    dispatch(MissionActions.setMode(MISSION_PAGE_MODES.DRAW_ZONE))
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.DRAW_POLYGON))
  }, [dispatch])

  const onAddStartPointButtonClicked = useCallback(() => {
    setDropdownState(DROPDOWN_STATES.DRAW_START_POINT)
    dispatch(MissionActions.setMode(MISSION_PAGE_MODES.VIEW_ZONES))
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.ADD_MISSION_START_POINT))
  }, [dispatch])

  const onAddFinishPointButtonClicked = useCallback(() => {
    setDropdownState(DROPDOWN_STATES.DRAW_FINISH_POINT)
    dispatch(MissionActions.setMode(MISSION_PAGE_MODES.VIEW_ZONES))
    dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.ADD_MISSION_FINISH_POINT))
  }, [dispatch])

  const onClick = (): void => {
    setOpen(prev => !prev)
  }

  const onClickAway = (): void => {
    setOpen(prev => !prev)
  }

  const contentsList = {
    [`${DROPDOWN_STATES.DRAW_ZONE}`]: <DrawZoneContent />,
    [`${DROPDOWN_STATES.DRAW_START_POINT}`]: <AddStartPointContent />,
    [`${DROPDOWN_STATES.DRAW_FINISH_POINT}`]: <AddFinishPointContent />,
  }

  return (
    <div
      className={clsx(style.button, style.buttonWide, style.positionRelative, dropdownActive ? style.active : '')}
      onClick={onClick}
    >
      {contentsList[dropdownState]}
      <ArrowSvg className={style.dropdownArrow} />
      {open ? (
        <ClickAwayListener onClickAway={onClickAway}>
          <div className={style.dropdown}>
            <CommonButton
              className={clsx(style.bottomShadow, style.button, style.buttonWide)}
              onClick={onDrawZoneButtonClicked}
            >
              <DrawZoneContent />
            </CommonButton>
            <CommonButton
              className={clsx(style.bottomShadow, style.button, style.buttonWide)}
              onClick={onAddStartPointButtonClicked}
              disabled={!!startPoint}
            >
              <AddStartPointContent />
            </CommonButton>
            <CommonButton
              className={clsx(style.bottomShadow, style.button, style.buttonWide)}
              onClick={onAddFinishPointButtonClicked}
              disabled={!!finishPoint}
            >
              <AddFinishPointContent />
            </CommonButton>
          </div>
        </ClickAwayListener>
      ) : null}
    </div>
  )
}
