import { Tabs } from '@mui/material'
import { MainApi } from 'api/MainApi'
import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import { StyledTab } from 'components/StyledTab/StyledTab'
import TabPanel from 'components/TabPanel/TabPanel'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import Title from 'components/Title/Title'
import isEqual from 'lodash.isequal'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { MissionActions } from 'store/actions/mission'
import {
  selectGeoTiffLayer,
  selectMissionInfo,
  selectMissionTempVisualSettings,
  selectMissionVisualSettings,
} from 'store/selectors/mission'
import store from 'store/store'
import { layerThunks } from 'store/thunks/layer'
import { DEFAULT_VIZUALIZATION_SETTINGS, GEOTIFF_LAYER_STATUSES, PROGRESS_TYPES } from 'utils/constants'

import ScheduleSettings from './ScheduleSettings/ScheduleSettings'
import VisualSettings from './VisualSettings/VisualSettings'

import style from './MissionSettingsModal.module.css'

interface IDialogProps {
  isOpen: boolean
  missionId: number
  missionName: string
  onClose: () => void
}

const MissionSettingsModal = ({ isOpen, missionId, missionName, onClose }: React.PropsWithChildren<IDialogProps>) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const inputFile = useRef<HTMLInputElement | null>(null)
  const missionInfo = useSelector(selectMissionInfo)
  const geoTiffLayer = useSelector(selectGeoTiffLayer)
  const visualSettings = useSelector(selectMissionVisualSettings)
  const tempVisualSettings = useSelector(selectMissionTempVisualSettings)

  const [tempLayerName, setTempLayerName] = useState(geoTiffLayer?.layer?.name || 'GeoTIFF Layer')
  const [tempMissionName, setTempMissionName] = useState(missionName)
  const [hasNameError, setNameHasError] = useState(false)
  const [actionInProgress, setActionInProgress] = useState(false)
  const [tab, setTab] = useState(0)

  const onMissionNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setNameHasError(true)
    } else {
      setNameHasError(false)
      setTempMissionName(e.target.value)
    }
  }

  const onLayerNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      setTempLayerName(e.target.value)
    }
  }

  const layerMessage = useMemo(() => {
    if (!geoTiffLayer.layer?.status) {
      return t('mission_settings.geotiff_description')
    }
    switch (geoTiffLayer.layer?.status) {
      case GEOTIFF_LAYER_STATUSES.ERROR:
        return t('mission_settings.geotiff_error')
      case GEOTIFF_LAYER_STATUSES.OK:
        return t('mission_settings.geotiff_status_ok')
      case GEOTIFF_LAYER_STATUSES.NEW:
        return t('mission_settings.geotiff_status_new')
      case GEOTIFF_LAYER_STATUSES.PENDING:
        return t('mission_settings.geotiff_status_pending')
    }
  }, [geoTiffLayer.layer?.status, t])

  const onCancelClicked = () => {
    if (!actionInProgress) {
      onClose()
      if (!isEqual(tempVisualSettings, visualSettings)) {
        dispatch(MissionActions.setTempVisualSettings({ ...visualSettings }))
      }
    }
  }

  const onSaveClicked = async () => {
    setActionInProgress(true)

    try {
      if (tempMissionName !== missionInfo?.name) {
        const mission = await MainApi.changeMissionName(missionId, tempMissionName)
        dispatch(MissionActions.setMissionName(mission.name || ''))
      }

      if (!isEqual(tempVisualSettings, visualSettings)) {
        const mission = await MainApi.changeMissionVisualSettings(missionId, tempVisualSettings)
        dispatch(
          MissionActions.setVisualSettings({ ...DEFAULT_VIZUALIZATION_SETTINGS, ...mission.visualization_settings })
        )
      }
    } catch (error) {
      console.log(error)
    }

    setActionInProgress(false)
    onClose()
  }

  const onSelectFileClick = () => {
    inputFile.current?.click()
  }

  const onDeleteLayerClick = () => {
    if (geoTiffLayer.layer?.id) {
      store.dispatch(layerThunks.deleteLayer(geoTiffLayer.layer?.id))
    }
  }

  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const target = event.target
    const files = target?.files
    if (files && missionInfo?.id) {
      store.dispatch(layerThunks.createLayer(missionInfo?.id, tempLayerName, files[0]))
      event.target.value = ''
    }
  }

  const deletingLayerInProgress = geoTiffLayer.deletingProgress === PROGRESS_TYPES.WORK
  const uploadingLayerInProgress = geoTiffLayer.uploadingProgress === PROGRESS_TYPES.WORK
  const isSaveButtonDisabled =
    deletingLayerInProgress ||
    ((tempMissionName === missionName || tempMissionName === '') && isEqual(visualSettings, tempVisualSettings))

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancelClicked}
      className={style.root}
      style={{
        overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(27, 43, 72, 0.6)',
        },
      }}
      ariaHideApp={false}
    >
      <div className={style.modal}>
        <div className={style.head}>
          <Title text={t('mission_settings.title')} className={style.title} />
        </div>
        <Tabs
          value={tab}
          onChange={(e, newValue: number) => {
            setTab(newValue)
          }}
          variant="fullWidth"
          indicatorColor="primary"
          sx={{ height: 45, minHeight: 45 }}
          centered
        >
          <StyledTab
            label="Main"
            sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0, maxWidth: 185 }}
            disableRipple
          />
          <StyledTab
            label={'Schedule...'}
            sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0, maxWidth: 185 }}
            disableRipple
          />
        </Tabs>
        <TabPanel value={tab} index={0} disablePadding={true}>
          <div className={style.content}>
            <TextFieldDescribed
              className={style.textfield}
              description={t('mission_settings.name_description')}
              error={t('mission_settings.name_error')}
              placeholder={t('mission_settings.name_placeholder')}
              hasError={hasNameError}
              defaultValue={missionName}
              onChange={onMissionNameChanged}
            />
            <div className={style.contentColumns}>
              <TextFieldDescribed
                className={style.rightMargin}
                description={t('mission_settings.buffer_description')}
                rightLabel={t('mission_settings.meters')}
                placeholder={t('mission_settings.name_placeholder')}
                defaultValue="25"
                onChange={() => {}}
              />
              <TextFieldDescribed
                description={t('mission_settings.start_point_max_distance')}
                rightLabel={t('mission_settings.meters')}
                placeholder={t('mission_settings.name_placeholder')}
                defaultValue="33"
                onChange={() => {}}
              />
            </div>
            <div className={style.selectFileBlock}>
              <TextFieldDescribed
                className={style.textfieldFullWidth}
                description={t('mission_settings.geotiff_map_layer')}
                placeholder={t('mission_settings.name_placeholder')}
                defaultValue={tempLayerName}
                disabled={
                  uploadingLayerInProgress || deletingLayerInProgress || geoTiffLayer.layer?.status !== undefined
                }
                onChange={onLayerNameChanged}
              />
              {!geoTiffLayer.layer && (
                <Button.Outlined
                  className={style.btnSelect}
                  onClick={onSelectFileClick}
                  loading={uploadingLayerInProgress}
                  disabled={uploadingLayerInProgress}
                >
                  {uploadingLayerInProgress ? t('mission_settings.uploading_file') : t('mission_settings.select_file')}
                </Button.Outlined>
              )}
              {geoTiffLayer.layer && (
                <Button.Outlined
                  className={style.btnSelect}
                  onClick={onDeleteLayerClick}
                  loading={deletingLayerInProgress}
                  disabled={deletingLayerInProgress}
                >
                  {deletingLayerInProgress ? t('mission_settings.deleting_layer') : t('mission_settings.delete_layer')}
                </Button.Outlined>
              )}
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                accept=" image/tif, .tif"
                multiple={false}
                onChange={onFileSelected}
              />
            </div>
            <div
              className={
                geoTiffLayer.layer?.status === GEOTIFF_LAYER_STATUSES.ERROR
                  ? clsx(style.geotiffDescription, style.error)
                  : style.geotiffDescription
              }
            >
              {layerMessage}
            </div>
            <VisualSettings />
          </div>
        </TabPanel>
        <TabPanel value={tab} index={1} disablePadding={true}>
          <div className={style.content}>
            <ScheduleSettings />
          </div>
        </TabPanel>
        <div className={style.footer}>
          <Button.Outlined className={style.btn} onClick={onCancelClicked} disabled={actionInProgress}>
            {t('mission_settings.label_cancel')}
          </Button.Outlined>
          <Button.Standart
            disabled={isSaveButtonDisabled || actionInProgress}
            loading={actionInProgress}
            className={style.btn}
            onClick={onSaveClicked}
          >
            {t('mission_settings.label_save')}
          </Button.Standart>
        </div>
      </div>
    </Modal>
  )
}

export default MissionSettingsModal
