import { MainApi } from 'api/MainApi'
import { MissionControlApi } from 'api/MissionControlApi'
import { RobotApi } from 'api/RobotApi'
import { MissionActions } from 'store/actions/mission'
import { MissionControlActions } from 'store/actions/missionControl'
import { RobotActions } from 'store/actions/robot'
import { PROGRESS_TYPES } from 'utils/constants'
import { RootState } from 'utils/interfaces'

const fetchMissionAndRobot = (jobId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(MissionControlActions.setLoadingProgress(PROGRESS_TYPES.WORK))

      const job = await MissionControlApi.fetchJob(jobId)

      const mission = await MainApi.fetchMission(job.mission_id)
      dispatch(MissionActions.setMission(mission))

      const robot = await RobotApi.fetchRobot(job.robot_id)
      dispatch(RobotActions.setRobotInfo(robot))

      dispatch(MissionControlActions.setLoadingProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(MissionControlActions.setLoadingProgress(PROGRESS_TYPES.ERROR))
      console.log('Error fetchMissionAndRobot')
    }
  }
}

const resetMissionAndRobot = () => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
    } catch (error) {
      console.log('Error resetMissionAndRobot')
    }
  }
}

export const missionControlThunks = {
  fetchMissionAndRobot,
  resetMissionAndRobot,
}
