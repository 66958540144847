import { createSlice } from '@reduxjs/toolkit'
import { Position } from 'geojson'
import turf from 'turf'
import { MAX_TRAJECTORY_DISTANCE, MAX_TRAJECTORY_POINTS, MISSION_JOB_STATUSES, PROGRESS_TYPES } from 'utils/constants'
import { IRobotInfo, IRobotState, IRobotTelemetry } from 'utils/interfaces'

export const initialState: IRobotState = {
  loadingProgress: PROGRESS_TYPES.IDLE,
  robotInfo: undefined,
  telemetry: undefined,
  trajectory: {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [],
    },
    properties: {},
  },
  jobInfo: {
    creatingProgress: PROGRESS_TYPES.IDLE,
    uploadingProgress: PROGRESS_TYPES.IDLE,
  },
}

export const robotSlice = createSlice({
  name: 'robot',
  initialState: initialState,
  reducers: {
    setLoadingProgress: (state: IRobotState, action: { payload: PROGRESS_TYPES }) => {
      state.loadingProgress = action.payload
    },
    setRobotInfo: (state: IRobotState, action: { payload: IRobotInfo }) => {
      state.robotInfo = action.payload
    },
    setTelemetry: (state: IRobotState, action: { payload: IRobotTelemetry }) => {
      state.telemetry = action.payload
    },
    pushPositionToTrajectory: (state: IRobotState, action: { payload: Position }) => {
      const [lastPoint] = state.trajectory.geometry.coordinates.slice(-1)
      if (
        lastPoint &&
        turf.distance(turf.point(lastPoint), turf.point(action.payload), 'meters') < MAX_TRAJECTORY_DISTANCE
      ) {
        return
      }
      if (state.trajectory.geometry.coordinates.length >= MAX_TRAJECTORY_POINTS) {
        state.trajectory.geometry.coordinates.shift()
      }
      state.trajectory.geometry.coordinates.push(action.payload)
    },
    setCreatingJobProgress: (state: IRobotState, action: { payload: PROGRESS_TYPES }) => {
      state.jobInfo.creatingProgress = action.payload
    },
    setUploadingJobProgress: (state: IRobotState, action: { payload: PROGRESS_TYPES }) => {
      state.jobInfo.uploadingProgress = action.payload
    },
    setJobId: (state: IRobotState, action: { payload: number }) => {
      if (state.robotInfo?.job) {
        state.robotInfo.job.id = action.payload
      }
    },
    setJobStatus: (state: IRobotState, action: { payload: MISSION_JOB_STATUSES }) => {
      if (state.robotInfo?.job) {
        state.robotInfo.job.status = action.payload
      }
    },
    resetRobot: () => {
      return initialState
    },
  },
})

export const RobotActions = robotSlice.actions

export default robotSlice.reducer
