import { MainApi } from 'api/MainApi'
import cloneDeep from 'lodash.clonedeep'
import { MissionActions } from 'store/actions/mission'
import { MISSION_MAP_DRAWING_MODES, MISSION_PAGE_MODES, TRANSFER_CALCULATING_STATUSES } from 'utils/constants'
import { IZoneTransferRoute, RootState } from 'utils/interfaces'

const patchMissionTransfer = () => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const transferId = getState().mission.tempTransfer?.properties.id
      if (transferId) {
        await MainApi.patchMissionPointTransferRoute(transferId, getState().mission.tempTransfer?.geometry)
      }
      const tempTransfer = cloneDeep(getState().mission.tempTransfer as IZoneTransferRoute)
      if (tempTransfer) {
        tempTransfer.userGeometry = tempTransfer.geometry
        // tempTransfer.geometry = { type: 'LineString', coordinates: [] }
        tempTransfer.properties.status = TRANSFER_CALCULATING_STATUSES.NEW
        dispatch(MissionActions.editMissionTransfer(tempTransfer))
      }
      dispatch(MissionActions.setEditingTransfer(undefined))
      dispatch(MissionActions.setTempTransfer(undefined))
      dispatch(MissionActions.setMode(MISSION_PAGE_MODES.VIEW_ZONES))
      dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
    } catch (error) {
      console.log('Error editing route')
      dispatch(MissionActions.setEditingTransfer(undefined))
      dispatch(MissionActions.setMode(MISSION_PAGE_MODES.VIEW_ZONES))
      dispatch(MissionActions.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES))
    }
  }
}

const deleteMissionTransfer = (transferId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      await MainApi.deleteMissionPointTransferRoute(transferId)
      dispatch(MissionActions.deleteMissionTransfer(transferId))
    } catch (error) {
      console.log('Error deleting transfer')
    }
  }
}

export const missionPointsThunks = {
  patchMissionTransfer,
  deleteMissionTransfer,
}
