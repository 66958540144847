import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { isActiveFeature, isInactiveFeature, isVertex, noTarget } from '@mapbox/mapbox-gl-draw/src/lib/common_selectors'
import cloneDeep from 'lodash.clonedeep'

const DirectSelectZoneMode = cloneDeep(MapboxDraw.modes.direct_select)

DirectSelectZoneMode.clickInactive = function (state, e) {
  this.map.fire('click', e)
}

DirectSelectZoneMode.clickNoTarget = function () {}

DirectSelectZoneMode.dragFeature = function () {}

DirectSelectZoneMode.onClick = function (state, e) {
  if (noTarget(e)) return this.clickNoTarget(state, e)
  if (isActiveFeature(e)) return this.clickActiveFeature(state, e)
  if (isInactiveFeature(e)) return this.clickInactive(state, e)

  if (isVertex(e)) {
    if (this.clickedVertex === state.selectedCoordPaths[0]) {
      this.onTrash(state)
      this.clickedVertex = undefined
    } else {
      this.clickedVertex = state.selectedCoordPaths[0]
      setTimeout(() => {
        this.clickedVertex = undefined
      }, 300)
    }
  }
  this.stopDragging(state)
}

export default DirectSelectZoneMode
