import Loader from 'components/Loader/Loader'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RobotActions } from 'store/actions/robot'
import { selectRobotLoadingProgress } from 'store/selectors/robot'
import store from 'store/store'
import { robotThunks } from 'store/thunks/robot'
import { PROGRESS_TYPES } from 'utils/constants'

import RobotView from './RobotView/RobotView'

import style from './Robot.module.css'

const Robot = () => {
  const loadingProgress = useSelector(selectRobotLoadingProgress)
  const params = useParams()
  const robotId = parseFloat(params.id || '')
  const dispatch = useDispatch()

  useEffectOnce(() => {
    store.dispatch(robotThunks.fetchRobot(robotId))
    return () => {
      dispatch(RobotActions.resetRobot())
    }
  })

  const stateList = {
    [`${PROGRESS_TYPES.SUCCESS}`]: <RobotView />,
    [`${PROGRESS_TYPES.WORK}`]: <Loader />,
    [`${PROGRESS_TYPES.ERROR}`]: <span>Error</span>,
  }

  return <div className={style.container}>{stateList[loadingProgress]}</div>
}

export default Robot
