import API from 'api/api'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { LANGUAGES, REACT_APP_I18N_DEBUG } from 'utils/constants'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: REACT_APP_I18N_DEBUG,
    lng: LANGUAGES.EN,
    fallbackLng: LANGUAGES.EN,
    supportedLngs: [LANGUAGES.EN, LANGUAGES.RU],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}.json',
      crossDomain: true,
      withCredentials: true,
      request: (_options, url, _payload, callback) => {
        API.get(url).then((res: any) => callback(null, res))
      },
    },
  })

export default i18n
