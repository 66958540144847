import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { cursors, events, modes } from '@mapbox/mapbox-gl-draw/src/constants'
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors'
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom'
import isEventAtCoordinates from '@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates'

const DrawPolygonMode = MapboxDraw.modes.draw_polygon

DrawPolygonMode.clickAnywhere = function (state, e) {
  if (
    state.currentVertexPosition > 0 &&
    isEventAtCoordinates(e, state.polygon.coordinates[0][state.currentVertexPosition - 1])
  ) {
    return this.changeMode(modes.DIRECT_SELECT, { featureId: state.polygon.id })
  }
  this.updateUIClasses({ mouse: cursors.ADD })
  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat)
  state.currentVertexPosition++
  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat)
}

DrawPolygonMode.clickOnVertex = function (state) {
  if (state.currentVertexPosition > 2) {
    return this.changeMode(modes.DIRECT_SELECT, { featureId: state.polygon.id })
  }
}

DrawPolygonMode.onStop = function (state) {
  this.updateUIClasses({ mouse: cursors.NONE })
  doubleClickZoom.enable(this)
  this.activateUIButton()

  // check to see if we've deleted this feature
  if (this.getFeature(state.polygon.id) === undefined) return

  //remove last added coordinate
  state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`)
  if (state.polygon.isValid()) {
    this.map.fire(events.CREATE, {
      features: [state.polygon.toGeoJSON()],
    })
  } else {
    this.deleteFeature([state.polygon.id], { silent: true })
    // this.changeMode(modes.DIRECT_SELECT, { featureId: state.polygon.id }, { silent: true })
  }
}

DrawPolygonMode.onKeyUp = function (state, e) {
  if (CommonSelectors.isEscapeKey(e)) {
    state.currentVertexPosition = 0
    state.polygon.coordinates = [[]]
  } else if (CommonSelectors.isEnterKey(e)) {
    this.changeMode(modes.DIRECT_SELECT, { featureId: state.polygon.id })
  }
}

export default DrawPolygonMode
