import { FeatureCollection, Geometry } from 'geojson'
import { useMemo } from 'react'
import { FillLayer, Layer, LineLayer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { selectMissionTempVisualSettings } from 'store/selectors/mission'
import { IRestrictZone } from 'utils/interfaces'

const RestrictZonesOnMap = ({ restrictZones }: { restrictZones: IRestrictZone[] }) => {
  const restrictZonesFeatureCollections: FeatureCollection<Geometry> = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: restrictZones,
    }
  }, [restrictZones])
  const visualSettings = useSelector(selectMissionTempVisualSettings)

  const restrictZonesFillLayer = useMemo<FillLayer>(() => {
    return {
      id: 'restrict-zones-fill',
      type: 'fill',
      paint: {
        'fill-color': visualSettings.restrict_fill_color,
        'fill-opacity': visualSettings.restrict_opacity,
      },
    }
  }, [visualSettings])

  const restrictZonesOutlineLayer = useMemo<LineLayer>(() => {
    return {
      id: 'restrict-zones-outline',
      type: 'line',
      paint: {
        'line-color': visualSettings.restrict_border_color,
        'line-width': visualSettings.restrict_width,
      },
    }
  }, [visualSettings])

  return (
    <Source id="resctrict-zones" type="geojson" data={restrictZonesFeatureCollections}>
      <Layer {...restrictZonesFillLayer} />
      <Layer {...restrictZonesOutlineLayer} />
    </Source>
  )
}

export default RestrictZonesOnMap
