import clsx from 'clsx'
import Box from 'components/Box/Box'
import { Button } from 'components/Button/Button'
import GarageOnMap from 'components/GarageOnMap/GarageOnMap'
import MapboxCommon from 'components/MapboxMap/MapboxCommon'
import Title from 'components/Title/Title'
import { Point } from 'geojson'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MapboxMap } from 'react-map-gl'
import { IGarage, IMapboxElement, IRobotInfo } from 'utils/interfaces'

import AddRobotModal from './AddRobotModal/AddRobotModal'
import AllRobotsOnMap from './AllRobotsOnMap/AllRobotsOnMap'
import RobotItem from './RobotItem/RobotItem'

import style from './RobotListView.module.css'

const RobotListView = ({
  robots,
  garages,
  onRobotsUpdated,
  onGaragesChanged,
}: {
  robots: IRobotInfo[]
  garages: IGarage[]
  onRobotsUpdated: () => void
  onGaragesChanged: () => void
}) => {
  const { t } = useTranslation()
  const [addingNewRobot, setAddingNewRobot] = useState(false)
  const mapRef = useRef<IMapboxElement>(null)
  const [map, setMap] = useState<MapboxMap | undefined>(undefined)
  const [highlightedRobotId, setHighlightedRobotId] = useState<number | undefined>(undefined)

  const onMapInited = () => {
    const map = mapRef.current?.getMap()
    if (map) {
      setMap(map)
    }
  }

  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <Title text={t('robots.title')} />{' '}
        <Button.Standart className={style.button} onClick={() => setAddingNewRobot(true)}>
          {t('robots.add_robot')}
        </Button.Standart>
      </div>
      <div className={style.main}>
        <Box className={style.box}>
          <div className={style.tableWrapper}>
            <div className={style.tableHeader}>
              <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('robots.serial_id')}</div>
              <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('robots.name')}</div>
              <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('robots.status')}</div>
              <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('robots.mission')}</div>
              <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('robots.mission_status')}</div>
              {/* <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('robots.location')}</div> */}
              <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('robots.last_seen')}</div>
            </div>
            {robots.length ? (
              <div className={style.table}>
                {robots.map(robot => (
                  <RobotItem
                    key={robot.id}
                    garages={garages}
                    robot={robot}
                    onRobotUpdated={onRobotsUpdated}
                    hightlighted={robot.id === highlightedRobotId}
                    onGaragesChanged={onGaragesChanged}
                    robots={robots}
                  />
                ))}
              </div>
            ) : (
              <div className={style.emptyWrapper}>
                <div className={style.emptyTitle}>{t('robots.no_robots_title')}</div>
                <div className={style.emptyDescription}>{t('robots.no_robots_description')}</div>
              </div>
            )}
          </div>
        </Box>
        <MapboxCommon ref={mapRef} disableRuler={true} overlayItems={[]} onMapInited={onMapInited}>
          <GarageOnMap
            map={map}
            garages={garages.filter(garage => garage.point).map(garage => garage.point) as Point[]}
          />
          <AllRobotsOnMap map={map} robots={robots} onRobotClicked={setHighlightedRobotId} />
        </MapboxCommon>
      </div>
      <AddRobotModal
        isOpen={addingNewRobot}
        garages={garages}
        onClose={() => setAddingNewRobot(false)}
        onSave={onRobotsUpdated}
        onGaragesChanged={onGaragesChanged}
        robots={robots}
      />
    </div>
  )
}

export default RobotListView
