import { MainApi } from 'api/MainApi'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MissionActions } from 'store/actions/mission'
import { selectMissionEditingTransfer, selectTransferRoutes } from 'store/selectors/mission'
import store from 'store/store'
import { missionThunks } from 'store/thunks/mission'
import { TRANSFER_TYPE } from 'utils/constants'

import CommonRouteItem from './CommonRouteItem/CommonRouteItem'

const TransferRouteItem = ({
  type,
  zoneIdToTransfer,
  hideActions,
}: {
  type: TRANSFER_TYPE
  zoneIdToTransfer?: number
  hideActions?: boolean
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const trasferRoutes = useSelector(selectTransferRoutes)
  const missionEditingTransfer = useSelector(selectMissionEditingTransfer)
  const transferRoute = useMemo(() => {
    const route = trasferRoutes.find(route => route.properties.zone_id_to === zoneIdToTransfer)
    return route
  }, [trasferRoutes, zoneIdToTransfer])

  const onTransferRouteClicked = () => {
    dispatch(MissionActions.setHighlightedFeature(transferRoute))
  }

  const onEditRouteClicked = () => {
    if (transferRoute) {
      store.dispatch(missionThunks.setEditingTransfer(transferRoute))
    }
  }

  const onRemoveRouteClicked = () => {
    if (transferRoute?.properties.id) {
      store.dispatch(missionThunks.deleteTransfer(transferRoute?.properties.id))
    }
  }

  const onCancelEditingClicked = (e: any) => {
    e.stopPropagation()
    store.dispatch(missionThunks.cancelEditingTransfer())
  }

  const onSaveClicked = (e: any) => {
    e.stopPropagation()
    store.dispatch(missionThunks.editTransfer())
  }

  const onCalculateClicked = async () => {
    try {
      if (transferRoute?.properties.id) {
        await MainApi.startCalculatingTransferRoute(transferRoute?.properties.id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const text = useMemo(() => {
    switch (type) {
      case TRANSFER_TYPE.START:
        return t('view_zones.transfer_route_auto_start')
      case TRANSFER_TYPE.MIDDLE:
        return t('view_zones.transfer_route_auto')
      case TRANSFER_TYPE.FINISH:
        return t('view_zones.transfer_route_auto_finish')
      default:
        return t('view_zones.transfer_route_auto')
    }
  }, [t, type])

  const editing = useMemo(() => {
    return missionEditingTransfer === transferRoute
  }, [missionEditingTransfer, transferRoute])

  return (
    <>
      {transferRoute?.id ? (
        <CommonRouteItem
          id={transferRoute.id}
          text={text}
          itemType={type}
          editing={editing}
          status={transferRoute.properties.status}
          length={transferRoute.properties.length}
          onClick={onTransferRouteClicked}
          onEdit={onEditRouteClicked}
          onCancel={onCancelEditingClicked}
          onRemove={onRemoveRouteClicked}
          onSave={onSaveClicked}
          hideActions={hideActions}
          onCalculate={onCalculateClicked}
        />
      ) : null}
    </>
  )
}

export default TransferRouteItem
