import { FormControlLabel, Switch, TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { useState } from 'react'

import style from '../MissionSettingsModal.module.css'

const ScheduleSettings = () => {
  const [weekdaysChecked, setWeekdaysChecked] = useState(false)
  const [date, setDate] = useState(new Date())
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label="Choose date and time"
          value={date}
          onChange={e => {
            setDate(e || new Date())
          }}
          renderInput={(params: any) => <TextField {...params} />}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={weekdaysChecked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setWeekdaysChecked(event.target.checked)
              }}
            />
          }
          label="Set a cleaning schedule by day of the week"
          sx={{ paddingTop: 3 }}
        />
        {weekdaysChecked && (
          <div className={style.weekdays}>
            <FormControlLabel control={<Switch defaultChecked />} label="Sunday" />
            <FormControlLabel control={<Switch />} label="Monday" />
            <FormControlLabel control={<Switch />} label="Tuesday" />
            <FormControlLabel control={<Switch />} label="Wednesday" />
            <FormControlLabel control={<Switch />} label="Thursday" />
            <FormControlLabel control={<Switch />} label="Friday" />
            <FormControlLabel control={<Switch />} label="Saturday" />
          </div>
        )}
      </LocalizationProvider>
    </>
  )
}

export default ScheduleSettings
